import { Button, Modal } from 'react-bootstrap';
import {
  setShowCancelConfirmationModal,
  setShowRescheduleModal,
} from '../../store/UpdateBookingSlice';
import { useDispatch } from 'react-redux';

const CancelAppointmentConfirmationModal = ({
  show,
  action,
}: {
  show: boolean;
  action: () => void;
}) => {
  const dispatch = useDispatch();

  return (
    <Modal
      size='lg'
      show={show}
      className='ca-modal-main'
      backdrop='static'
      enforceFocus={false}
      backdropClassName='front'
      dialogClassName='modal-min-90'
      aria-labelledby='contained-modal-title-vcenter'
      scrollable={true}
      centered
    >
      <Modal.Header>
        <div className='ca-exit'>
          <button
            onClick={() => {
              action();
            }}
            className='eha-close'
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='ca-modal'>
          <div className='ca-calendar-x'>
            <img src='/img/cancel_icon.svg' />
          </div>

          <div className='ca-modal-title'>Appointment cancelled!</div>

          <div className='ca-note'>
            We have informed the service provider about the cancellation. Please book another
            appointment if you would like to select another date or schedule a new appointment
          </div>

          <Button
            className='ca-button'
            onClick={() => {
              dispatch(setShowCancelConfirmationModal(false));
              dispatch(setShowRescheduleModal(true));
            }}
          >
            Book again
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelAppointmentConfirmationModal;

export const statusSwitch = (status: string) => {

  switch (status) {
    case 'cancelled':
      return 'danger'
    case 'in_progress':
      return 'warning'
    case 'completed':
      return 'success'
    case 'confirmed':
      return 'secondary'
    case 'pending':
      return 'warning'
    default:
      return 'light'
  }
}

export const valueSwitch = (status: string) => {

  switch (status) {
    case 'cancelled':
      return 'Cancelled'
    case 'in_progress':
      return 'In-progress'
    case 'completed':
      return 'Completed'
    case 'confirmed':
      return 'Confirmed'
    case 'pending':
      return 'Pending'
    default:
      return ''
  }
}
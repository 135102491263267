import React from 'react';
import Button from 'react-bootstrap/Button';
import LoadingSpinner from '@components/loading/LoadingSpinner';


interface FormButtonProps {
  children: React.ReactNode,
  className?: string,
  loading?: boolean,
  active?: boolean,
  as?: any,
  disabled?: boolean,
  href?: string,
  size?: any,
  type?: any,
  variant?: string,
  onClick?:() => void
}

const FormButton = ({children, loading, className, ...otherProps} : FormButtonProps) => (

  <Button className={(loading? 'loading ' : '') + (className? className : '')} {...otherProps}>
    {loading &&
      <span className="loading-dots">
        <LoadingSpinner size="sm"></LoadingSpinner>
        <LoadingSpinner size="sm"></LoadingSpinner>
        <LoadingSpinner size="sm"></LoadingSpinner>
      </span>
    }
    {children}
  </Button>
);

export default FormButton;

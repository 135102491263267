/* eslint-disable camelcase */
import { Modal } from 'react-bootstrap'
import InputContainer from '@components/form/InputContainer'
import FormButton from '@components/form/FormButton'
import Select, { MultiValue } from 'react-select'
import DeleteButton from '@components/form/DeleteButton'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'src/store'
import * as providersSlice from '../../store/ServiceProvidersSlice'
import * as providerService from '../../services/providerServices'
import * as serviceServices from '../../services/serviceServices'
import * as branchServices from '../../services/branchServices'
import LoadingSpinner from '@components/loading/LoadingSpinner'

type Props = {
  onHide: () => void
  show: boolean
  provider: providersSlice.Provider
}

interface ServiceOptions {
  service_id: number
  label: string
  value: string
}
interface BranchOptions {
  branch_id: number
  label: string
  value: string
}

const EditAndDeleteProviderModal = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>()
  const { provider } = props
  const [serviceProvider, setServiceProvider] = useState(provider)
  const {serviceOptions, offset, limit} = useSelector((state: AppState) => state.providers)
  const allServices = useSelector((state: AppState) => state.providers.allServices)
  const branchOptions = useSelector((state: AppState) => state.providers.branchOptions)
  const existingBranches = useSelector((state: AppState) => state.providers.existingBranches)
  const existingServices = useSelector((state: AppState) => state.providers.existingServices)
  const allBranches = useSelector((state: AppState) => state.providers.allBranches)

  const [error, setError] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [isSaving, setIsSaving] = useState(false)


  useEffect(() => {
    setServiceProvider(provider)
  }, [provider])

  useEffect(() => {
    const getProvider = async () => {
      const data = await providerService.getProvider(provider.id as number)
      if (data) {
        setLoaded(true)
      }
      data.services.map((eachService: { id: number; name: string }) => {
        const serviceOptionsDropdown = {} as ServiceOptions
        serviceOptionsDropdown.service_id = eachService.id as number
        serviceOptionsDropdown.label = eachService.name
        serviceOptionsDropdown.value = eachService.name.toLowerCase()
        if (serviceOptions?.length !== data?.services?.length) {
          dispatch(providersSlice.setServiceOptions(serviceOptionsDropdown))
          dispatch(providersSlice.setExistingServices(serviceOptionsDropdown))
        }
        return eachService
      })
      data.branches.map((eachBranch: { id: number; name: string }) => {
        const branchOptionsDropdown = {} as BranchOptions
        branchOptionsDropdown.branch_id = eachBranch.id as number
        branchOptionsDropdown.label = eachBranch.name
        branchOptionsDropdown.value = eachBranch.name.toLowerCase()
        if (branchOptions?.length !== data?.branches?.length) {
          dispatch(providersSlice.setBranchOptions(branchOptionsDropdown))
          dispatch(providersSlice.setExistingBranches(branchOptionsDropdown))
        }
        return eachBranch
      })
    }
    getProvider()
  }, [])

  useEffect(() => {
    const serviceAndBranch = async () => {
      const services = await serviceServices.getAllServices()
      services.map((eachService: { id: number; name: string }) => {
        const allServiceOptionsDropdown = {} as ServiceOptions
        allServiceOptionsDropdown.service_id = eachService.id as number
        allServiceOptionsDropdown.label = eachService.name
        allServiceOptionsDropdown.value = eachService.name.toLowerCase()

        if (allServices?.length !== services?.length) {
          dispatch(providersSlice.setAllServices(allServiceOptionsDropdown))
        }
        return eachService
      })

      const branches = await branchServices.getAllBranches()
      branches.map((eachBranch: { id: number; name: string }) => {
        const allBranchOptionsDropdown = {} as BranchOptions
        allBranchOptionsDropdown.branch_id = eachBranch.id as number
        allBranchOptionsDropdown.label = eachBranch.name
        allBranchOptionsDropdown.value = eachBranch.name.toLowerCase()

        if (allBranches?.length !== branches?.length) {
          dispatch(providersSlice.setAllBranches(allBranchOptionsDropdown))
        }
        return eachBranch
      })
    }
    serviceAndBranch()
  }, [])

  const updateBranchOptions = (branches: MultiValue<BranchOptions>) => {
    dispatch(providersSlice.updateBranchOptions(branches))
  }
  const updateServiceOptions = (services: MultiValue<ServiceOptions>) => {
    dispatch(providersSlice.updateServiceOptions(services))
  }

  const editServiceProvider = async () => {
    setIsSaving(true)
    if (
      serviceProvider.name.length > 0 &&
      serviceProvider.desc.length > 0 &&
      serviceProvider.email.toString().trim() !== '' &&
      serviceProvider.client_limit.toString().trim() !== ''
    ) {
      const updatedProvider = await providerService.updateProvider(serviceProvider)
      if (updatedProvider) {
        for (const eachService of existingServices) {
          const providerServices = {
            service_id: eachService.service_id,
            provider_id: serviceProvider.id as number,
          }
          await providerService.deleteProviderService(providerServices)
        }
        for (const eachBranch of existingBranches) {
          const providerBranches = {
            branch_id: eachBranch.branch_id,
            provider_id: serviceProvider.id as number,
          }
          await providerService.deleteProviderBranch(providerBranches)
        }

        const providerWithNoServiceOrBranch = await providerService.getProvider(
          serviceProvider.id as number,
        )

        let updatedProviderWithServiceContainer = []
        let updatedProviderWithBranchContainer = []
        if (
          providerWithNoServiceOrBranch &&
          providerWithNoServiceOrBranch.services &&
          (providerWithNoServiceOrBranch.services.length as number) === 0 &&
          providerWithNoServiceOrBranch.branches &&
          (providerWithNoServiceOrBranch.branches.length as number) === 0
        ) {
          for (const eachService of serviceOptions) {
            const providerServices = {
              service_id: eachService.service_id,
              provider_id: serviceProvider.id as number,
            }
            const updatedProviderWithService = await providerService.updateProviderServices(
              providerServices,
            )
            updatedProviderWithServiceContainer = updatedProviderWithService.services
          }

          for (const eachBranch of branchOptions) {
            const providerBranches = {
              branch_id: eachBranch.branch_id,
              provider_id: serviceProvider.id as number,
            }
            const updatedProviderWithBranch = await providerService.updateProviderBranches(
              providerBranches,
            )
            updatedProviderWithBranchContainer = updatedProviderWithBranch.branches
          }

          if (
            (updatedProviderWithServiceContainer.length as number) === serviceOptions.length &&
            (updatedProviderWithBranchContainer.length as number) === branchOptions.length
          ) {
            dispatch(providersSlice.selectProvider(updatedProvider))
            const providers = await providerService.getAllProviders(offset,limit)
            dispatch(providersSlice.allProviders(providers))
            dispatch(providersSlice.setProviderServices(updatedProviderWithServiceContainer))
            dispatch(providersSlice.setProviderBranches(updatedProviderWithBranchContainer))

            setIsSaving(false)
            setError(false)
            props.onHide()
          }
        }
      }
    } else {
      setIsSaving(false)
      setError(false)
      props.onHide()
    }
  }
  const deleteServiceProvider = async () => {
    setIsDeleting(true)
    const result = await providerService.deleteProvider(serviceProvider)
    if (result && result.status === 204) {
      const providers = await providerService.getAllProviders(offset,limit)
      dispatch(providersSlice.selectProvider(providers[0]))
      dispatch(providersSlice.allProviders(providers))

      setIsDeleting(false)
      props.onHide()
    }
  }

  return (
    <Modal size='lg' {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Edit Service Provider Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loaded && (
          <>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='name' label='Provider Name' required={true}>
                  <input
                    type='text'
                    defaultValue={serviceProvider.name}
                    onChange={(item) =>
                      setServiceProvider({
                        ...serviceProvider,
                        name: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='code' label='Provider Code' required={true}>
                  <input
                    type='text'
                    defaultValue={serviceProvider.code}
                    onChange={(item) =>
                      setServiceProvider({ ...serviceProvider, code: item.target.value })
                    }
                  />
                </InputContainer>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='email' label='Email' required={true}>
                  <input
                    type='email'
                    defaultValue={serviceProvider.email}
                    onChange={(item) =>
                      setServiceProvider({
                        ...serviceProvider,
                        email: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='phone' label='Phone Number'>
                  <input
                    type='number'
                    defaultValue={serviceProvider.phone}
                    onChange={(item) =>
                      setServiceProvider({ ...serviceProvider, phone: item.target.value })
                    }
                  />
                </InputContainer>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='client' label='Client Limit' required={true}>
                  <input
                    type='number'
                    defaultValue={serviceProvider.client_limit}
                    onChange={(item) =>
                      setServiceProvider({
                        ...serviceProvider,
                        client_limit: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='image' label='Provider Image'>
                  <input
                    type='text'
                    defaultValue={serviceProvider.provider_image}
                    onChange={(item) =>
                      setServiceProvider({
                        ...serviceProvider,
                        provider_image: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='services' label='Services'>
                  <Select
                    instanceId='Service'
                    isMulti
                    defaultValue={serviceOptions}
                    onChange={(service) => updateServiceOptions(service)}
                    options={allServices}
                    className='select'
                    classNamePrefix='select'
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='location' label='Branches'>
                  <Select
                    instanceId='Branches'
                    isMulti
                    defaultValue={branchOptions}
                    onChange={(branch) => updateBranchOptions(branch)}
                    options={allBranches}
                    className='select'
                    classNamePrefix='select'
                  />
                </InputContainer>
              </div>
            </div>
            <InputContainer name='Description' label='Description' required={true}>
              <textarea
                defaultValue={provider.desc}
                onChange={(item) => setServiceProvider({ ...provider, desc: item.target.value })}
                rows={2}
              ></textarea>
            </InputContainer>
          </>
        )}
        <div className='text-danger text-center my-2'>
          {error && 'One or more items is required'}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {isDeleting ? (
          <LoadingSpinner />
        ) : (
          <DeleteButton name='Service Provider' onClick={deleteServiceProvider}>
            Delete
          </DeleteButton>
        )}
        <FormButton className='ms-auto' loading={isSaving} onClick={editServiceProvider}>Save</FormButton>
      </Modal.Footer>
    </Modal>
  )
}
export default EditAndDeleteProviderModal

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Calendar, dateFnsLocalizer, SlotInfo, ToolbarProps } from 'react-big-calendar';
import { format, startOfWeek, getDay, isSameDay } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './_calendar.scss';
import { getProviderSchedule } from '../../services/bookings';
import { setCreateDate, setSelectedTime } from '../../store/UpdateBookingSlice';
import moment from 'moment';

const locales = { 'en-US': enUS };
const localizer = dateFnsLocalizer({
  format,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales,
});

const CustomToolbar: React.FC<ToolbarProps> = ({ label, onNavigate }) => (
  <div className="rbc-toolbar">
    <span className="rbc-btn-group">
      <button type="button" className="rbc-nav-button" onClick={() => onNavigate('PREV')}>
        <span className="rbc-nav-icon">&#8249;</span>
        Prev Month
      </button>
    </span>
    <span className="rbc-toolbar-label">{label}</span>
    <span className="rbc-btn-group rbc-btn-group-right">
      <button type="button" className="rbc-nav-button" onClick={() => onNavigate('NEXT')}>
        Next Month
        <span className="rbc-nav-icon">&#8250;</span>
      </button>
    </span>
  </div>
);

interface RescheduleCalendarProps {
  setSelectedDateTime: React.Dispatch<React.SetStateAction<string>>;
  providerId: number; 
}

const RescheduleCalendar: React.FC<RescheduleCalendarProps> = ({ setSelectedDateTime, providerId }) => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTimeLocal] = useState<string | null>(null);
  const [providerTimes, setProviderTimes] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);

  const handleSelectSlot = async (slotInfo: SlotInfo) => {
    setSelectedDate(slotInfo.start);
    dispatch(setCreateDate(slotInfo.start.toISOString()));
    const dayOfWeek = getDay(slotInfo.start);

    try {
      setError(null);
      const schedule = await getProviderSchedule({ providerId, dayOfWeek }); 
      const times = schedule.map((s) => s.time);
      setProviderTimes(times);
    } catch (error) {
      setError('Oops, error rescheduling appointment. Please contact admin.');
    }
  };

  const handleSelectTime = (time: string) => {
    setSelectedTimeLocal(time);
    dispatch(setSelectedTime(time));

    if (selectedDate) {
      const dateTime = moment(selectedDate).format('YYYY-MM-DD') + 'T' + time;
      setSelectedDateTime(dateTime);
    }
  };

  const dayPropGetter = (date: Date) => ({
    className: selectedDate && isSameDay(date, selectedDate) ? 'selected-date' : '',
  });

  return (
    <div className="calendar-container">
      <div className="calendar-wrapper">
        <Calendar
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 400 }}
          selectable
          onSelectSlot={handleSelectSlot}
          defaultView="month"
          views={['month']}
          components={{ toolbar: CustomToolbar }}
          dayPropGetter={dayPropGetter}
        />
      </div>
      {providerTimes.length > 0 && (
        <div className="provider-times">
          <h3>Available Times</h3>
          <div className="times-grid">
            {providerTimes.map((time, index) => (
              <div
                key={index}
                className={`time-slot ${selectedTime === time ? 'selected-time' : ''}`}
                onClick={() => handleSelectTime(time)}
              >
                {moment(time, 'HH:mm:ss').format('hh:mma')}
              </div>
            ))}
          </div>
        </div>
      )}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default RescheduleCalendar;

import React, { ReactNode } from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { Provider } from 'react-redux'
import keycloak from '../keycloak'
import { store } from '../store'

const KeyCloakAuthWrapper = ({ children }: { children: ReactNode }) => {
  const eventLogger = (event: unknown, error: unknown) => {
    console.log('onKeycloakEvent', event, error)
  }

  const tokenLogger = (tokens: unknown) => {
    console.log('onKeycloakTokens', tokens)
  }
  return (
    <ReactKeycloakProvider
      initOptions={{
        checkLoginIframe: false,
      }}
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
    >
      <Provider store={store}>{children}</Provider>
    </ReactKeycloakProvider>
  )
}

export default KeyCloakAuthWrapper

import ApiException from '@components/exceptions/ApiExceptions'
import axios from 'axios'
import * as branchesSlice from '../store/BranchesSlice'

interface Error {
    response: {
        status: number
    }
}
export const getAllBranches = async () => {
    try {
        const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/branches`)
        return result.data
    } catch (error) {
        const typedError = error as Error
        throw new ApiException(typedError.response.status);
    }

}
export const createBranch = async (params: branchesSlice.Branch) => {
    try {
        const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/branches`, params)
        return result.data
    } catch (error) {
        const typedError = error as Error
        throw new ApiException(typedError.response.status);
    }

}
export const updateBranch = async (params: branchesSlice.Branch) => {
    try {
        const result = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/branches/${params.id}`, params)
        return result.data;
    } catch (error) {
        const typedError = error as Error
        throw new ApiException(typedError.response.status);
    }

}
export const deleteBranch = async (params: branchesSlice.Branch) => {
    try {
        const result = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/branches/${params.id}`)
        return result;
    } catch (error) {
        const typedError = error as Error
        throw new ApiException(typedError.response.status);
    }
}
import React from 'react';

type Props = {
  size?: string;
  className?: string;
}

const LoadingSpinner = ({size, className}: Props) => (

  <div className={'loading-spinner ' + (size? size : '') + ' ' + className}>
    <div className="circle2"></div>
    <div className="circle1"></div> 
  </div>
);

export default LoadingSpinner;

import React from 'react';
import { Dropdown } from 'react-bootstrap';


interface ActionProps {
  text: string,
  key: number,
  handler?: () => void
}

interface ActionMenuProps {
  small?: boolean,
  actions: ActionProps[],
  testID?: string;
  roleEllipseButton?: string
  roleActionHandler?: string
}

const ActionMenu = ({ actions, small, testID, roleEllipseButton, roleActionHandler }: ActionMenuProps) => {
  const size = small ? ' small' : '';

  return (
    <Dropdown className={'action-menu' + size}>
      <Dropdown.Toggle role={roleEllipseButton} >
        <i className='eha-dots' data-testid={testID}></i>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {actions.map((action: ActionProps) => (
          <Dropdown.Item role={roleActionHandler} onClick={action.handler} key={action.key} href='#'>
            {action.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionMenu;

/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Calendar, ToolbarProps, View, dateFnsLocalizer, SlotInfo } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { format, startOfWeek, getDay, parseISO, addDays } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { getUnavailabilitySlots, createUnavailabilitySlot } from '../../services/providerServices';
import { Button } from 'react-bootstrap';
import {
  CALENDAR_VIEWS,
  generateCustomTimeRanges,
  determineTitle,
  determineSecondTitle,
  SlotLabelCombination,
} from '../../utils/providerCalendar';
import { useKeycloak } from '@react-keycloak/web';
import { SlotCombination, TimeSlot } from '../../resources/providerCalendar';

interface Event {
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
}

const locales = { 'en-US': enUS };
const localizer = dateFnsLocalizer({
  format,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 0 }),
  getDay,
  locales,
});

const timeRanges = generateCustomTimeRanges();
interface ProviderCalendarProps {
  hideSaturday: boolean;
  hideSunday: boolean;
  providerId: number;
  dateFrom: string;
  slotId: number;
}

const CustomToolbar: React.FC<ToolbarProps> = ({ label, onNavigate, onView, view }) => {
  const [activeView, setActiveView] = useState<View>(view);

  useEffect(() => {
    setActiveView(view);
  }, [view]);

  const handleViewChange = (view: View) => {
    setActiveView(view);
    onView(view);
  };

  return (
    <div className='rtb-toolbar'>
      <div className='rtb-toolbar-top'>
        <p>Set Schedule</p>
        <div className='rtb-cal-btn'>
          <button
            type='button'
            className={`rbc-btn ${activeView === 'day' ? 'active' : ''}`}
            onClick={() => handleViewChange('day')}
          >
            Day
          </button>
          <button
            type='button'
            className={`rbc-btn ${activeView === 'week' ? 'active' : ''}`}
            onClick={() => handleViewChange('week')}
          >
            Week
          </button>
          <button
            type='button'
            className={`rbc-btn ${activeView === 'month' ? 'active' : ''}`}
            onClick={() => handleViewChange('month')}
          >
            Month
          </button>
        </div>
      </div>
      <div className='rtb-navigation'>
        <button className='rtb-nav-button' onClick={() => onNavigate('PREV')}>
          <span className='rtb-nav-icon'>&#8249;</span>
          Prev
        </button>
        <span className='rtb-toolbar-label'>{label}</span>
        <button className='rtb-nav-button' onClick={() => onNavigate('NEXT')}>
          Next
          <span className='rtb-nav-icon'>&#8250;</span>
        </button>
      </div>
    </div>
  );
};

const CustomEvent: React.FC<{ event: any }> = ({ event }) => {
  const dayOfWeek = getDay(event.start);

  const colors = [
    { background: 'rgba(255, 102, 102, 0.2)', border: 'rgba(255, 102, 102, 5)', font: '#660000' },
    { background: 'rgba(153, 255, 102, 0.2)', border: 'rgba(153, 255, 102, 5)', font: '#336600' },
    { background: 'rgba(255, 204, 102, 0.2)', border: 'rgba(255, 204, 102, 5)', font: '#666600' },
    { background: 'rgba(255, 153, 102, 0.2)', border: 'rgba(255, 153, 102, 5)', font: '#663300' },
    { background: 'rgba(102, 255, 153, 0.2)', border: 'rgba(102, 255, 153, 5)', font: '#006633' },
    { background: 'rgba(102, 204, 255, 0.2)', border: 'rgba(102, 204, 255, 5)', font: '#003366' },
    { background: 'rgba(204, 102, 255, 0.2)', border: 'rgba(204, 102, 255, 5)', font: '#660066' },
  ];

  const { background, border, font } = colors[dayOfWeek] || colors[0];

  return (
    <div
      className='custom-event'
      style={{
        backgroundColor: background,
        borderColor: border,
        borderWidth: '8px',
        borderStyle: 'solid',
      }}
    >
      <div className='shift-time' style={{ color: font }}>
        {event.title}
      </div>
      <div className='shift-title' style={{ color: font }}>
        {event.SecondTitle}
      </div>
    </div>
  );
};

const ProviderCalendar: React.FC<ProviderCalendarProps> = ({
  hideSaturday,
  hideSunday,
  providerId,
  dateFrom,
  slotId,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [menuPosition, setMenuPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [firstSelectedTimeRange, setfirstSelectedTimeRange] = useState<{
    label: string;
    from: string;
    to: string;
  } | null>(null);
  const [secondSelectedTimeRange, setsecondSelectedTimeRange] = useState<{
    label: string;
    from: string;
    to: string;
  } | null>(null);
  const [events, setEvents] = useState<Event[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [currentView, setCurrentView] = useState<View>('month');
  const [isLoading, setIsLoading] = useState(false);
  const [isAllDay, setIsAllDay] = useState(false);

  const { keycloak } = useKeycloak();

  const refreshEvents = async () => {
    try {
      const response = await getUnavailabilitySlots(providerId);

      const slots = response.flatMap((slot: any) =>
        slot.slot_json.map((timeSlot: any) => {
          const startTime = `${slot.date_from}T${timeSlot.from_slot}`;
          const endTime = `${slot.date_from}T${timeSlot.to_slot}`;
          const title = determineTitle(timeSlot.from_slot, timeSlot.to_slot);
          const SecondTitle = determineSecondTitle(timeSlot.from_slot, timeSlot.to_slot);

          return {
            title: title,
            SecondTitle: SecondTitle,
            start: parseISO(startTime),
            end: parseISO(endTime),
            allDay: false,
          };
        }),
      );

      setEvents(slots);
      setError(null);
    } catch (error) {
      setError('Oops, Failed to refresh. Please contact admin.');
    }
  };

  useEffect(() => {
    const fetchUnavailabilitySlotDetails = async () => {
      try {
        const response = await getUnavailabilitySlots(providerId);

        const slots = response.flatMap((slot: any) => {
          return slot.slot_json.map((timeSlot: any) => {
            const startTime = `${slot.date_from}T${timeSlot.from_slot}`;
            const endTime = `${slot.date_from}T${timeSlot.to_slot}`;
            const title = determineTitle(timeSlot.from_slot, timeSlot.to_slot);
            const SecondTitle = determineSecondTitle(timeSlot.from_slot, timeSlot.to_slot);

            return {
              title: title,
              SecondTitle: SecondTitle,
              start: parseISO(startTime),
              end: parseISO(endTime),
              allDay: false,
            };
          });
        });

        setEvents(slots);
        setError(null);
      } catch (error) {
        setError('Oops, Failed to fetch unavailability slots. Please contact admin.');
      }
    };

    fetchUnavailabilitySlotDetails();
  }, [providerId, dateFrom, slotId]);

  const dayPropGetter = (date: Date) => {
    if (currentView !== 'month') {
      if ((hideSaturday && getDay(date) === 6) || (hideSunday && getDay(date) === 0)) {
        return { className: 'hidden-day' };
      }
    }
    return {};
  };

  const handleSelectSlot = (slotInfo: SlotInfo) => {
    if (slotInfo.box) {
      setSelectedDate(slotInfo.start);

      const dayOfWeek = getDay(slotInfo.start);
      let menuLeftPosition = slotInfo.box.clientX;

      if (dayOfWeek === 4 || dayOfWeek === 5 || dayOfWeek === 6) {
        menuLeftPosition -= 160;
      } else if (dayOfWeek === 3) {
        menuLeftPosition =
          slotInfo.box.clientX - (document.querySelector('.menu-container')?.clientWidth || 0) / 2;
      }

      setMenuPosition({ top: slotInfo.box.clientY, left: menuLeftPosition });
      setIsMenuOpen(true);
    }
  };
  const handleSubmitTimeRange = async () => {
    if (selectedDate) {
      let slotJson;
      const formattedDate = format(selectedDate, 'yyyy-MM-dd');
      const nextDay = format(addDays(selectedDate, 1), 'yyyy-MM-dd');

      if (isAllDay) {
        slotJson = [
          {
            date: formattedDate,
            from_slot: TimeSlot.AllDay.split('-')[0],
            to_slot: TimeSlot.AllDay.split('-')[1],
          },
        ];
      } else {
        slotJson = [
          {
            date: formattedDate,
            from_slot: firstSelectedTimeRange ? firstSelectedTimeRange.from : '',
            to_slot: firstSelectedTimeRange ? firstSelectedTimeRange.to : '',
          },
        ];

        if (firstSelectedTimeRange?.label === 'Night') {
          slotJson.push({
            date: formattedDate,
            from_slot: secondSelectedTimeRange ? secondSelectedTimeRange.from : '',
            to_slot: secondSelectedTimeRange ? secondSelectedTimeRange.to : '',
          });

          slotJson.push({
            date: nextDay,
            from_slot: firstSelectedTimeRange ? firstSelectedTimeRange.from : '',
            to_slot: firstSelectedTimeRange ? firstSelectedTimeRange.to : '',
          });
        } else {
          slotJson.push({
            date: formattedDate,
            from_slot: secondSelectedTimeRange ? secondSelectedTimeRange.from : '',
            to_slot: secondSelectedTimeRange ? secondSelectedTimeRange.to : '',
          });
        }
      }

      slotJson = slotJson.filter((slot) => slot.from_slot && slot.to_slot);

      const params = {
        date_from: formattedDate,
        date_to: formattedDate,
        slot_json: slotJson,
        create_username: keycloak?.tokenParsed?.preferred_username,
      };

      try {
        setIsLoading(true);
        await createUnavailabilitySlot(providerId, params);
        await refreshEvents();
        setIsMenuOpen(false);
        setfirstSelectedTimeRange(null);
        setsecondSelectedTimeRange(null);
        setIsAllDay(false);
        setIsLoading(false);
      } catch (error) {
        setError('Oops, Failed to create unavailability slots. Please contact admin.');
        setIsLoading(false);
      }
    }
  };

  const isConfirmButtonEnabled = () => {
    if (isAllDay) {
      return !!selectedDate;
    }
    const isValidSet = Object.values(SlotCombination).some((combination) => {
      const labels = SlotLabelCombination[combination];
      return (
        firstSelectedTimeRange?.label === labels.first &&
        secondSelectedTimeRange?.label === labels.second
      );
    });

    return !!selectedDate && !!firstSelectedTimeRange && !!secondSelectedTimeRange && isValidSet;
  };

  return (
    <div>
      {error && <div className='error-message'>{error}</div>}
      <Calendar
        localizer={localizer}
        startAccessor='start'
        endAccessor='end'
        style={{ height: 585 }}
        defaultView='month'
        views={CALENDAR_VIEWS}
        dayPropGetter={dayPropGetter}
        components={{
          toolbar: (props) => {
            useEffect(() => {
              setCurrentView(props.view);
            }, [props.view]);

            return <CustomToolbar {...props} view={currentView} />;
          },
          event: CustomEvent,
        }}
        selectable
        onSelectSlot={handleSelectSlot}
        events={events}
      />
      {isMenuOpen && selectedDate && (
        <div className='menu-container' style={{ top: menuPosition.top, left: menuPosition.left }}>
          <div className='menu-content'>
            <div className='menu-header'>
              <div className='selected-date'>
                {selectedDate && format(selectedDate, 'MMMM d, yyyy')}
              </div>
              <button
                className='close-menu'
                onClick={() => {
                  setIsMenuOpen(false);
                  setfirstSelectedTimeRange(null);
                  setsecondSelectedTimeRange(null);
                }}
              >
                X
              </button>
            </div>

            <div>
              <select
                value={firstSelectedTimeRange ? firstSelectedTimeRange.label : ''}
                onChange={(e) => {
                  const selectedRange = Object.values(timeRanges)
                    .flat()
                    .find((range) => range.label === e.target.value);
                  setfirstSelectedTimeRange(selectedRange || null);
                }}
                disabled={isAllDay}
              >
                <option value=''>Select First Slot</option>
                {Object.values(timeRanges)
                  .flat()
                  .map((range, index) => (
                    <option key={index} value={range.label}>
                      {range.label}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <select
                value={secondSelectedTimeRange ? secondSelectedTimeRange.label : ''}
                onChange={(e) => {
                  const selectedRange = Object.values(timeRanges)
                    .flat()
                    .find((range) => range.label === e.target.value);
                  setsecondSelectedTimeRange(selectedRange || null);
                }}
                disabled={isAllDay}
              >
                <option value=''>Select Second Slot</option>
                {Object.values(timeRanges)
                  .flat()
                  .map((range, index) => (
                    <option key={index} value={range.label}>
                      {range.label}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <label className='all-day-label'>
                <input
                  type='checkbox'
                  checked={isAllDay}
                  onChange={(e) => {
                    setIsAllDay(e.target.checked);
                    if (e.target.checked) {
                      setfirstSelectedTimeRange({ label: '', from: '00:00', to: '23:59' });
                      setsecondSelectedTimeRange(null);
                    } else if (firstSelectedTimeRange?.label === '') {
                      setfirstSelectedTimeRange(null);
                    }
                  }}
                />
                All Day
              </label>
            </div>
            <Button
              variant='primary'
              onClick={handleSubmitTimeRange}
              disabled={!isConfirmButtonEnabled() || isLoading}
            >
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProviderCalendar;

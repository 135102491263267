import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import { Link, useLocation } from 'react-router-dom'

import { useKeycloak } from '@react-keycloak/web';

export default function TopNav() {

  const location = useLocation().pathname

  const { keycloak } = useKeycloak();

  return (
    <Navbar
      bg='dark'
      variant='dark'
      expand='md'
      fixed='top'
      className='eha-topnav'
    >
      <Navbar.Brand href='/' className='mx-3'>
        <img src='/img/EHABooking-logo.svg' height='40px' className='pt-2 ps-2' />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' className='mx-3' />
      <Navbar.Collapse id='basic-navbar-nav'>
        {!!keycloak.authenticated && (
          <>
            <Nav activeKey={location}>
              <Nav.Link as={Link} to='/' test-id='config-nav'>
                Configuration
              </Nav.Link>
              <Nav.Link as={Link} to='/reports/bookings' test-id='reports-nav'>
                Reports
              </Nav.Link>

            </Nav>
            <Nav className='ms-auto'>
              <NavDropdown
                title={keycloak?.tokenParsed?.preferred_username}
                id='basic-nav-dropdown'
                className='user-name'
                test-id='user-name'
              >
                <NavDropdown.Item href='#action/3.1' test-id='profile-nav'>
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  href='#'
                  test-id='logout-nav'
                  onClick={() => keycloak.logout({ redirectUri: `${process.env.REACT_APP_BASE_URL}` })}
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </>
        )}
        {!keycloak.authenticated && (
          <Nav className='ms-auto'>
            <Button size='sm' href='#' onClick={() => keycloak.login()}>
              <i className='eha-checkin me-2' />
              Signin
            </Button>
          </Nav>
        )}

      </Navbar.Collapse>
    </Navbar>
  );
}

/* eslint-disable camelcase */
import InputContainer from '@components/form/InputContainer'
import FormButton from '@components/form/FormButton'
import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'src/store'
import * as branchService from '../../services/branchServices'
import * as branchesSlice from '../../store/BranchesSlice'
import { useKeycloak } from '@react-keycloak/web'

type Props = {
    show: boolean
    onHide: () => void
}

const CreateBranchModal = (props: Props) => {
    const { keycloak } = useKeycloak()
    const dispatch = useDispatch<AppDispatch>()
    const selectedBranch = useSelector((state: AppState) => state.branches.selectedBranch)
    const [error, setError] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [branch, setBranch] = useState({
        name: '',
        code: '',
        address: '',
        is_testcenter: false,
        create_username: keycloak?.tokenParsed?.preferred_username,
    })

    const handleCreateBranch = async () => {
        if (branch.name.length > 0 && branch.address.length > 0 && branch.code.length > 0) {
            setIsSaving(true)
            const result = await branchService.createBranch(branch)
            if (result && result.id) {
                setBranch({
                    name: '',
                    code: '',
                    address: '',
                    is_testcenter: false,
                    create_username: keycloak?.tokenParsed?.preferred_username,
                })
                const fetchBranches = await branchService.getAllBranches()
                if (selectedBranch === undefined) {
                    dispatch(branchesSlice.selectBranch(fetchBranches[0]))
                }
                dispatch(branchesSlice.allBranches(fetchBranches))
                setIsSaving(false)
                props.onHide()
            }
        } else {
            setError(true)
        }
    }

    return (
        <Modal size='lg' {...props} aria-labelledby='contained-modal-title-vcenter' centered>
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>Create Branch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-md-6'>
                        <InputContainer name='name' label='Branch Name' required={true}>
                            <input
                                type='text'
                                defaultValue={branch.name}
                                onChange={(item) =>
                                    setBranch({
                                        ...branch,
                                        name: item.target.value,
                                    })
                                }
                            />
                        </InputContainer>
                    </div>

                    <div className='col-md-6'>
                        <InputContainer name='Branch Code' label='Branch Code' required={true}>
                            <input
                                type='text'
                                defaultValue={branch.code}
                                onChange={(item) => setBranch({ ...branch, code: item.target.value })}
                            />
                        </InputContainer>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <InputContainer name='address' label='Address' required={true}>
                            <input
                                type='text'
                                defaultValue={branch.address}
                                onChange={(item) => setBranch({ ...branch, address: item.target.value })}
                            />
                        </InputContainer>
                    </div>
                </div>
                <div className='d-flex text-align-center mt-3'>
                    <label className='me-2'>Is this a test Center ?</label>
                    <div className='form-check form-switch'>
                        <input
                            className='form-check-input py-2'
                            type='checkbox'
                            id='flexSwitchCheckChecked'
                            defaultChecked={branch.is_testcenter}
                            onChange={() => setBranch({ ...branch, is_testcenter: !branch.is_testcenter })}
                        />
                    </div>
                </div>
                <div className='text-danger text-center my-2'>
                    {error && 'One or more items is required'}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='light' onClick={props.onHide}>
                    Cancel
                </Button>
                <FormButton loading={isSaving} onClick={handleCreateBranch}>Create</FormButton>
            </Modal.Footer>
        </Modal>
    )
}
export default CreateBranchModal

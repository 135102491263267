import React from 'react';
import {  useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { AppState } from '../../store';
import moment from 'moment';
import { addMinutes, dateToHrsMins } from '@utils/reformatDate';

interface RescheduleAppointmentConfirmationModalProps {
  show: boolean;
  action: () => void;
}

const RescheduleAppointmentConfirmationModal: React.FC<RescheduleAppointmentConfirmationModalProps> = ({ show, action }) => {

  const { booking} = useSelector((state: AppState) => state.updateBooking);

  if (!booking){
    return null;
  }
  const endTime = addMinutes(booking.start_time,  booking.service.duration);
  return (
    <Modal
      size='lg'
      show={show}
      className='ca-modal-main'
      backdrop='static'
      enforceFocus={false}
      backdropClassName='front'
      dialogClassName='modal-min-90'
      aria-labelledby='contained-modal-title-vcenter'
      scrollable={true}
      centered
    >
      <Modal.Header>
        <div className='ca-exit'>
          <button
            onClick={action} 
            className='eha-close'
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='ca-modal'>
          <div className='Blank-Calendar--Streamline-Core.svg'>
            <img src='/img/Blank-Calendar--Streamline-Core.svg' alt='Blank Calendar' /> 
          </div>

          <div className='ca-modal-title'>Appointment rescheduled!</div>

          <div className="ca-note">
            We have informed the service provider about the changes in your booking. Below
            are the details of your updated booking:
          </div>

          <div className="ca-display">
               <p className="center-text">
                {booking?.service?.name}
               </p>

            <div className='eha-date-time'>
              <div className='eha-date'>
                <div className='eha-calendar-empty'></div>
                {booking?.start_time ? moment(booking?.start_time).format('DD/MM/YYYY') : 'N/A'}
              </div>
              <div className='eha-time'>
                <div className='eha-clock-1'></div>
                {booking?.start_time ? moment(booking?.start_time).format('hh:mma') : 'N/A'}
              </div>
              <div className='eha-duration'>
                <div className='eha-hourglass-1'></div>
                {booking && booking.service ? dateToHrsMins(booking?.start_time, endTime) : 'N/A'}
              </div>
            </div>
          </div>

          <Button className="ca-button">Book again</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RescheduleAppointmentConfirmationModal;

import { useSelector, useDispatch } from 'react-redux'
import { AppState, AppDispatch } from 'src/store'
import * as branchesSlice from '../../store/BranchesSlice'
const SelectedBranches = () => {
  const dispatch = useDispatch<AppDispatch>()
  const branches = useSelector((state: AppState) => state.branches.branches)
  const selectedBranch = useSelector((state: AppState) => state.branches.selectedBranch)
  const searchInput = useSelector((state: AppState) => state.branches.searchInput)
  const filteredBranches = branches
    .filter((eachBranch) => {
      return eachBranch.name.toLowerCase().includes(searchInput.toLowerCase())
    })
    .sort((a, b) => {
      if (a.id !== undefined && b.id !== undefined) {
        return b.id - a.id
      }
      return 0
    })
  const showBranchDetails = (eachBranch: branchesSlice.Branch) => {
    dispatch(branchesSlice.selectBranch(eachBranch))
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return (
    <div>
      {filteredBranches.map((eachBranch) => (
        <div
          className={
            eachBranch.id === selectedBranch?.id ? 'list-item active' : 'list-item pointer'
          }
          key={eachBranch.id}
          onClick={() => showBranchDetails(eachBranch)}
        >
          <div>{eachBranch.name}</div>
          <input
            type='checkbox'
            readOnly
            checked={eachBranch.id === selectedBranch?.id ? true : false}
            className={eachBranch.id === selectedBranch?.id ? 'ms-auto' : 'ms-auto pointer'}
          />
        </div>
      ))}
    </div>
  )
}
export default SelectedBranches

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SideNav_selectedColor__ftJbI {
    color: black;
}
`, "",{"version":3,"sources":["webpack://./src/components/side-nav/SideNav.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB","sourcesContent":[".selectedColor {\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedColor": `SideNav_selectedColor__ftJbI`
};
export default ___CSS_LOADER_EXPORT___;

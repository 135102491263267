import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { AppState } from '../../store';
import {
  setShowConfirmRescheduleModal,
  setShowRescheduleModal,
  setShowRescheduleConfirmationModal,
} from '../../store/UpdateBookingSlice';
import { rescheduleAppointment } from '../../services/bookings';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import './_calendar.scss'; 

interface ConfirmRescheduleModalProps {
  show: boolean;
  onHide: () => void;
  selectedDateTime: string;
}

const ConfirmRescheduleModal: React.FC<ConfirmRescheduleModalProps> = ({ show, onHide, selectedDateTime }) => {
  const { booking } = useSelector((state: AppState) => state.updateBooking);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleConfirmReschedule = async () => {
    setLoading(true);
    setError(null);
    try {
      if (!booking || !booking.id) {
        throw new Error('No booking selected');
      }

      await rescheduleAppointment({ bookingId: booking.id, newDateTime: selectedDateTime });
      await queryClient.invalidateQueries({ queryKey: ['get-booking'] });

      dispatch(setShowRescheduleConfirmationModal(true));
      dispatch(setShowConfirmRescheduleModal(false));
      dispatch(setShowRescheduleModal(false));
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('Oops, error rescheduling appointment. Please contact admin.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      enforceFocus={false}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ textAlign: 'center', fontWeight: 'bold', width: '100%' }}>Confirm Reschedule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="error-message">{error}</div>}
        {booking?.start_time && (
          <p className="appointment-details current-appointment">
            Current Appointment: {moment(booking.start_time).format('DD/MM/YYYY')} at {moment(booking.start_time).format('hh:mma')}
          </p>
        )}
        <p className="appointment-details rescheduled-appointment">
          Are you sure you want to reschedule your appointment to {moment(selectedDateTime).format('DD/MM/YYYY [at] hh:mma')}?
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <Button variant="secondary" className="ca-button" onClick={handleConfirmReschedule} disabled={loading}>
            {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'CONFIRM '}
          </Button>
          <Button variant="primary" onClick={onHide} disabled={loading}>
            CANCEL 
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmRescheduleModal;

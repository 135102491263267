/* eslint-disable camelcase */
import { Modal, Button, Alert } from 'react-bootstrap'
import InputContainer from '@components/form/InputContainer'
import FormButton from '@components/form/FormButton'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'src/store'
import { TimePickerValue } from 'react-time-picker'
import Select, { MultiValue } from 'react-select'
import * as providersSlice from '../../store/ServiceProvidersSlice'
import * as providerService from '../../services/providerServices'
import { useKeycloak } from '@react-keycloak/web'
import { useQuery } from '@tanstack/react-query'
import * as serviceServices from '../../services/serviceServices'
import * as servicesSlice from '../../store/ServicesSlice'
import * as branchServices from '../../services/branchServices'
import * as branchSlices from '../../store/BranchesSlice'
import LoadingSpinner from '@components/loading/LoadingSpinner'
import * as texts from '../exceptions/texts'

type Props = {
  show: boolean
  onHide: () => void
}
type SelectDropdown = {
  value: string
  label: string
}
type Schedule = {
  day: string
  active: boolean
  sunrise: {
    startTime: TimePickerValue
    endTime: TimePickerValue
  }
  sunset: {
    startTime: TimePickerValue
    endTime: TimePickerValue
  }
}
export type Provider = {
  name: SelectDropdown
  icon: string
  description: string
  active: boolean
  services: MultiValue<SelectDropdown>
  branches: MultiValue<SelectDropdown>
  schedules: Schedule[]
}

const schedules = [
  {
    day: 'Monday',
    active: true,
    sunrise: {
      startTime: '08:00',
      endTime: '12:00',
    },
    sunset: {
      startTime: '13:00',
      endTime: '17:00',
    },
  },
  {
    day: 'Sunday',
    active: true,
    sunrise: {
      startTime: '08:00',
      endTime: '12:00',
    },
    sunset: {
      startTime: '13:00',
      endTime: '17:00',
    },
  },
]
interface ServiceOptions {
  service_id: number
  label: string
  value: string
}
interface BranchOptions {
  branch_id: number
  label: string
  value: string
}

const CreateProviderModal = (props: Props) => {
  const { keycloak } = useKeycloak()
  const dispatch = useDispatch<AppDispatch>()
  const [error, setError] = useState(false)
  const [serviceOptions] = useState<ServiceOptions[]>([])
  const [branchOptions] = useState<BranchOptions[]>([])
  const [selectedProviderServices, setSelectedProviderServices] = useState<
    MultiValue<ServiceOptions>
  >([])
  const [selectedProviderBranches, setSelectedProviderBranches] = useState<
    MultiValue<BranchOptions>
  >([])
  const services = useSelector((state: AppState) => state.services.services)
  const branches = useSelector((state: AppState) => state.branches.branches)
  const {offset,limit, selectedProvider} = useSelector((state: AppState) => state.providers)
  const [isSaving, setIsSaving] = useState(false)

  const { isError, isSuccess, isFetching } = useQuery(
    ['allServices'],
    () => serviceServices.getAllServices(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        dispatch(servicesSlice.allServices(data))
      },
    },
  )
  const allBranches = useQuery(['allBranches'], () => branchServices.getAllBranches(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      dispatch(branchSlices.allBranches(data))
    },
  })

  services.length > 0 &&
    services.map((eachService) => {
      const serviceOptionsDropdown = {} as ServiceOptions
      serviceOptionsDropdown.service_id = eachService.id as number
      serviceOptionsDropdown.label = eachService.name
      serviceOptionsDropdown.value = eachService.name.toLowerCase()

      if (serviceOptions?.length !== services?.length) {
        serviceOptions.push(serviceOptionsDropdown)
      }
      return eachService
    })
  branches.length > 0 &&
    branches.map((eachBranch) => {
      const branchOptionsDropdown = {} as BranchOptions
      branchOptionsDropdown.branch_id = eachBranch.id as number
      branchOptionsDropdown.label = eachBranch.name
      branchOptionsDropdown.value = eachBranch.name.toLowerCase()

      if (branchOptions?.length !== branches?.length) {
        branchOptions.push(branchOptionsDropdown)
      }
      return eachBranch
    })

  const [provider, setProvider] = useState<providersSlice.Provider>({
    name: '',
    desc: '',
    code: '',
    email: '',
    phone: '',
    provider_image: '',
    is_visible: true,
    enable_google_sync: true,
    client_limit: '',
    create_username: keycloak?.tokenParsed?.preferred_username,
  })

  const handleCreateProvider = async () => {
    setIsSaving(true)
    if (
      provider.name.length > 0 &&
      provider.desc.length > 0 &&
      provider.code.length > 0 &&
      provider.email.toString().trim() !== '' &&
      provider.client_limit.trim() !== ''
    ) {
      const createdProvider = await providerService.createProvider(provider)
      if (createdProvider && createdProvider.id) {
        if (selectedProviderBranches.length > 0) {
          for (const eachProviderBranch of selectedProviderBranches) {
            const providerBranches = {
              branch_id: eachProviderBranch.branch_id,
              provider_id: createdProvider.id,
            }
            const addedBranches = await providerService.updateProviderBranches(providerBranches)
            if (addedBranches?.branches?.length) {
              const fetchProviders = await providerService.getAllProviders(offset,limit)
              if (selectedProvider === undefined) {
                dispatch(providersSlice.selectProvider(fetchProviders[0]))
              }
              dispatch(providersSlice.allProviders(fetchProviders))
            }
          }
        }
        if (selectedProviderServices.length > 0) {
          for (const eachProviderService of selectedProviderServices) {
            const providerServices = {
              service_id: eachProviderService.service_id,
              provider_id: createdProvider.id,
            }
            const addedServices = await providerService.updateProviderServices(providerServices)

            if (
              addedServices &&
              addedServices.services &&
              (addedServices.services.length as number) === selectedProviderServices.length
            ) {
              const fetchProviders = await providerService.getAllProviders(offset,limit)
              if (selectedProvider === undefined) {
                dispatch(providersSlice.selectProvider(fetchProviders[0]))
              }
              dispatch(providersSlice.allProviders(fetchProviders))
            }
          }
        }
        if (selectedProviderBranches.length === 0 && selectedProviderServices.length === 0) {
          const fetchProviders = await providerService.getAllProviders(offset,limit)
          if (selectedProvider === undefined) {
            dispatch(providersSlice.selectProvider(fetchProviders[0]))
          }
          dispatch(providersSlice.allProviders(fetchProviders))
        }
        setIsSaving(false)
        setError(false)
        setProvider({
          name: '',
          desc: '',
          code: '',
          email: '',
          phone: '',
          provider_image: '',
          is_visible: true,
          enable_google_sync: true,
          client_limit: '',
          create_username: keycloak?.tokenParsed?.preferred_username,
        })
        props.onHide()
      }
    } else {
      setIsSaving(false)
      setError(true)
    }
  }
  return (
    <Modal size='lg' {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Create Service Provider</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {isFetching && (
          <div className='text-center'>
            <LoadingSpinner />
          </div>
        )}
        {isError && (
          <Alert variant='light' className='label m-0 text-danger'>
            <i className='eha-level4 text-danger me-2' />
            {texts.FETCHING_FAILED}
          </Alert>
        )}
        {isSuccess && allBranches.isSuccess && (
          <>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='name' label='Provider Name' required={true}>
                  <input
                    type='text'
                    defaultValue={provider.name}
                    onChange={(item) =>
                      setProvider({
                        ...provider,
                        name: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='code' label='Provider Code' required={true}>
                  <input
                    type='text'
                    defaultValue={provider.code}
                    onChange={(item) => setProvider({ ...provider, code: item.target.value })}
                  />
                </InputContainer>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='email' label='Email' required={true}>
                  <input
                    type='email'
                    defaultValue={provider.email}
                    onChange={(item) =>
                      setProvider({
                        ...provider,
                        email: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='phone' label='Phone Number'>
                  <input
                    type='number'
                    defaultValue={provider.phone}
                    onChange={(item) => setProvider({ ...provider, phone: item.target.value })}
                  />
                </InputContainer>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='client' label='Client Limit' required={true}>
                  <input
                    type='number'
                    defaultValue={provider.client_limit}
                    onChange={(item) =>
                      setProvider({
                        ...provider,
                        client_limit: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='image' label='Provider Image'>
                  <input
                    type='text'
                    defaultValue={provider.provider_image}
                    onChange={(item) =>
                      setProvider({ ...provider, provider_image: item.target.value })
                    }
                  />
                </InputContainer>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='services' label='Services'>
                  <Select
                    instanceId='Service'
                    isMulti
                    onChange={(service) => setSelectedProviderServices(service)}
                    options={serviceOptions}
                    className='select'
                    classNamePrefix='select'
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='location' label='Branches'>
                  <Select
                    instanceId='Branches'
                    isMulti
                    onChange={(branch) => setSelectedProviderBranches(branch)}
                    options={branchOptions}
                    className='select'
                    classNamePrefix='select'
                  />
                </InputContainer>
              </div>
            </div>
            <InputContainer name='Description' label='Description' required={true}>
              <textarea
                defaultValue={provider.desc}
                onChange={(item) => setProvider({ ...provider, desc: item.target.value })}
                rows={2}
              ></textarea>
            </InputContainer>
            <div className='text-danger text-center my-2'>
              {error && 'One or more items is required'}
            </div>
          </>
        )}
      </Modal.Body>
      {isSuccess && (
        <Modal.Footer>
          <Button variant='light' onClick={props.onHide}>
            Cancel
          </Button>
          <FormButton loading={isSaving} onClick={handleCreateProvider}>Create</FormButton>
        </Modal.Footer>
      )}
    </Modal>
  )
}
export default CreateProviderModal

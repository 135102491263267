import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'src/store'
import * as providersSlice from '../../store/ServiceProvidersSlice'

const SelectedProviders = () => {
  const newServiceProviders = useSelector((state: AppState) => state.providers.serviceProviders)
  const selectedProvider = useSelector((state: AppState) => state.providers.selectedProvider)
  const searchInput = useSelector((state: AppState) => state.providers.searchInput).toLowerCase()
  const filteredServiceProviders = newServiceProviders
    .filter((eachProvider) => {
      return eachProvider.name.toLowerCase().includes(searchInput.toLowerCase())
    })
    .sort((a, b) => {
      if (a.id !== undefined && b.id !== undefined) {
        return b.id - a.id
      }
      return 0
    })

  const dispatch = useDispatch<AppDispatch>()
  const showProviderDetails = (eachProvider: providersSlice.Provider) => {
    dispatch(providersSlice.selectProvider(eachProvider))
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div>
      {filteredServiceProviders.map((eachProvider) => (
        <div
          key={eachProvider.id}
          className={
            eachProvider.id === selectedProvider.id ? 'list-item active' : 'list-item pointer'
          }
          onClick={() => showProviderDetails(eachProvider)}
        >
          <div>{eachProvider?.name}</div>
          <input
            type='checkbox'
            readOnly
            checked={eachProvider.id === selectedProvider.id ? true : false}
            className={eachProvider.id === selectedProvider.id ? 'ms-auto' : 'ms-auto pointer'}
          />
        </div>
      ))}
    </div>
  )
}

export default SelectedProviders

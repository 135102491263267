import { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import moment from 'moment';
import { addMinutes, dateToHrsMins, reformatDate } from '@utils/reformatDate';

interface ILayout {
  children: ReactNode;
  type: string;
}

const UpdateBookingDetails = ({ children, type }: ILayout) => {
  const { booking } = useSelector((state: AppState) => state.updateBooking);

  const [showDetails, setShowDetails] = useState(false);

  const endTime = addMinutes(booking?.start_time, booking?.service.duration);

  return (
    <div className={`ca-main ${type === 'page' ? 'update-page' : 'update-modal'}`}>
      <div className='ca-body'>
        {booking ? (
          <div className='ca-header'>
            <div className='ca-header-column'>
              <div className='ca-header-title'>{booking?.service?.name}</div>
              <div className='ca-timing'>
                <div className='ca-timing-item'>
                  <div className='eha-calendar-empty'></div>
                  <div>{moment(booking?.start_time)?.format('DD-MM-YYYY')}</div>
                </div>
                <div className='ca-timing-item'>
                  <div className='eha-clock-1'></div>
                  <div>
                    {reformatDate(booking?.start_time)?.time} - {reformatDate(endTime)?.time}
                  </div>
                </div>
                <div className='ca-timing-item'>
                  <div className='eha-hourglass-1'></div>
                  <div>{dateToHrsMins(booking?.start_time, endTime)}</div>
                </div>
              </div>
            </div>

            {type === 'modal' ? (
              <div
                role='button'
                onClick={() => setShowDetails((prev) => !prev)}
                className='view-details'
              >
                {showDetails ? 'HIDE DETAILS' : 'SHOW DETAILS'}
              </div>
            ) : null}
          </div>
        ) : null}

        {type === 'page' || showDetails ? (
          <div className='ca-info'>
            <div className='ca-grid'>
              <div className='ca-grid-item'>
                <div className='ca-grid-item-label'>Service:</div>
                <div className='ca-grid-item-text'>{booking?.service?.name}</div>
              </div>

              <div className='ca-grid-item'>
                <div className='ca-grid-item-label'>Appointment type:</div>
                <div className='ca-grid-item-text'>Online Appointment Bookings</div>
              </div>

              <div className='ca-grid-item'>
                <div className='ca-grid-item-label'>Service provider:</div>
                <div className='ca-grid-item-text'>{booking?.provider?.name}</div>
              </div>

              <div className='ca-grid-item'>
                <div className='ca-grid-item-label'>Appointment code:</div>
                <div className='ca-grid-item-text'>{booking?.code}</div>
              </div>

              <div className='ca-grid-item'>
                <div className='ca-grid-item-label'>Created by:</div>
                <div className='ca-grid-item-text'>
                  {booking?.create_username}{' '}
                  {moment(booking?.create_date).format('DD-MM-YYYY  h:mma')}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {children}
      </div>
    </div>
  );
};

export default UpdateBookingDetails;

import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '@components/layout/Layout'
import NotFoundCSS from './NotFound.module.css'

const NotFound = () => (
  <Layout title="404 Not Found">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className={NotFoundCSS.template}>
            <h1>
              Oops!</h1>
            <h2>
              404 Not Found</h2>
            <div className={NotFoundCSS.details}>
              Sorry, the requested page is not found!
            </div>
            <div className={NotFoundCSS.actions}>
              <Link className="btn btn-primary btn-lg" to="/"> Go Home</Link>
              <Link className="btn btn-default btn-lg" to="/"> Contact Support</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFound;
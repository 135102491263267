import { FC } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './views';
import PrivateRoute from '@views/PrivateRoute';
import NotFound from '@views/NotFound';
import Services from '@components/services';
import ServiceProviders from '@components/service-providers';
import BookingView from '@views/reports/BookingView';
import ClientView from '@views/reports/ClientView';
import PatientAuth from '@views/PatientAuth';
import UpdateBooking from '@components/update-booking';
import KeyCloakAuthWrapper from '@views/KeyCloakAuthWrapper';
import { Provider } from 'react-redux';
import { store } from './store';

export const AppRoutes: FC = () => {
  return (
    <BrowserRouter>
      <div className='nav-bar'>
        <Routes>
          <Route
            index
            element={
              <KeyCloakAuthWrapper>
                <Home />
              </KeyCloakAuthWrapper>
            }
          />
          <Route
            path='/services'
            element={
              <KeyCloakAuthWrapper>
                <PrivateRoute>
                  <Services />
                </PrivateRoute>
              </KeyCloakAuthWrapper>
            }
          />
          <Route
            path='/providers'
            element={
              <KeyCloakAuthWrapper>
                <PrivateRoute>
                  <ServiceProviders />
                </PrivateRoute>
              </KeyCloakAuthWrapper>
            }
          />
          <Route
            path='/reports/bookings'
            element={
              <KeyCloakAuthWrapper>
                <PrivateRoute>
                  <BookingView />
                </PrivateRoute>
              </KeyCloakAuthWrapper>
            }
          />
          <Route
            path='/reports/clients'
            element={
              <KeyCloakAuthWrapper>
                <PrivateRoute>
                  <ClientView />
                </PrivateRoute>
              </KeyCloakAuthWrapper>
            }
          />
          <Route
            path='/bookings/:bookingId/:token'
            element={
              <Provider store={store}>
                <PatientAuth>
                  <UpdateBooking />
                </PatientAuth>
              </Provider>
            }
          />
          <Route
            path='*'
            element={
              <KeyCloakAuthWrapper>
                <NotFound />
              </KeyCloakAuthWrapper>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

import React, { ChangeEvent, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import UpdateBookingDetails from './UpdateBookingDetails';
import InputContainer from '@components/form/InputContainer';
import { cancelBooking } from '../../services/bookings';
import { useParams } from 'react-router-dom';
import { setShowCancelConfirmationModal, setShowCancelModal } from '../../store/UpdateBookingSlice';
import ConfirmationButton from '@components/form/ConfirmationButton';
import { COMPLETED, CANCELLED } from '../../constants/constant';
import { useQueryClient } from '@tanstack/react-query';

interface CancelAppointmentModalProps {
  show: boolean;
  action: () => void;
  onRescheduleClick: () => void;
}

const CancelAppointmentModal: React.FC<CancelAppointmentModalProps> = ({
  show,
  action,
  onRescheduleClick,
}) => {
  const { booking } = useSelector((state: AppState) => state.updateBooking);
  const dispatch = useDispatch();
  const { bookingId } = useParams<{ bookingId: string }>();

  const [error, setError] = useState<{ reason?: { message: string } }>();
  const [reason, setReason] = useState('');
  const [cancelLoading, setCancelLoading] = useState(false);
  const queryClient = useQueryClient();

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
    if (error) setError(undefined);
  };

  const handleCancel = async () => {
    if (!reason) {
      setError({ reason: { message: 'Please enter the reason for cancellation' } });
      return;
    }

    if (!bookingId) return;

    setCancelLoading(true);

    try {
      const res = await cancelBooking({ bookingId: Number(bookingId), reason });

      if (res.status === 200) {
        setReason('');
        await queryClient.invalidateQueries({ queryKey: ['get-booking'] });
        dispatch(setShowCancelConfirmationModal(true));
        dispatch(setShowCancelModal(false));
      }
    } catch (error) {
      // handle error if needed
    } finally {
      setCancelLoading(false);
    }
  };

  return (
    <Modal
      size='xl'
      show={show}
      className='update-modal-main'
      backdrop='static'
      enforceFocus={false}
      backdropClassName='front'
      aria-labelledby='contained-modal-title-vcenter'
      scrollable
      centered
    >
      <Modal.Header>
        <div className='ca-exit cancel-exit'>
          <button onClick={action} className='eha-close' />
        </div>
      </Modal.Header>
      <div className='update-modal-main-body'>
        <Modal.Body>
          <UpdateBookingDetails type='modal'>
            {booking?.status !== COMPLETED && booking?.status !== CANCELLED && (
              <div>
                <div className='ca-reason-section'>
                  <div>Why do you want to cancel this appointment?</div>
                  <InputContainer name='reason' errors={error} required label='Enter reason'>
                    <textarea
                      onChange={handleChange}
                      className='ca-textarea-reason'
                      value={reason}
                    />
                  </InputContainer>
                </div>
                <div className='ca-buttons-section'>
                  <Button variant='secondary' className='ca-button' onClick={onRescheduleClick}>
                    RESCHEDULE
                  </Button>
                  <div className='ca-buttons'>
                    <Button variant='light' className='ca-button2' onClick={action}>
                      DISMISS
                    </Button>
                    <ConfirmationButton
                      name='Cancel Booking'
                      actionMessage='Are you sure you want to cancel this booking?'
                      buttonColor='danger'
                      dialogPosition='top-right'
                      className='mx-1 px-3'
                      onClick={handleCancel}
                      disabled={cancelLoading}
                    >
                      CANCEL BOOKING
                    </ConfirmationButton>
                  </div>
                </div>
              </div>
            )}
          </UpdateBookingDetails>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default CancelAppointmentModal;

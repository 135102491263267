/* eslint-disable camelcase */
import DeleteButton from '@components/form/DeleteButton'
import InputContainer from '@components/form/InputContainer'
import FormButton from '@components/form/FormButton'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'
import * as branchesSlice from '../../store/BranchesSlice'
import * as branchService from '../../services/branchServices'

type Props = {
    onHide: () => void
    show: boolean
    branch: branchesSlice.Branch
}
const EditAndDeleteBranchModal = (props: Props) => {
    const dispatch = useDispatch<AppDispatch>()
    const { branch } = props
    const [selectedBranch, setSelectedBranch] = useState(branch)
    const [isSaving, setIsSaving] = useState(false)
    const [error, setError] = useState(false)
    const editSelectedBranch = async () => {
        if (
            selectedBranch && selectedBranch.name && selectedBranch.name.length > 0 && selectedBranch.address &&
            selectedBranch.address.length > 0 && selectedBranch.code.length > 0
        ) {
            setIsSaving(true)
            const result = await branchService.updateBranch(selectedBranch)
            if (result) {
                dispatch(branchesSlice.selectBranch(result))
                const branches = await branchService.getAllBranches()
                dispatch(branchesSlice.allBranches(branches))
                setError(false)
                setIsSaving(false)
                props.onHide()
            }
        } else {
            setError(true)
        }
    }
    const deleteSelectedBranch = async() => {
        const result = await branchService.deleteBranch(selectedBranch)
        if (result && result.status === 204) { 
            const branches = await branchService.getAllBranches()
            dispatch(branchesSlice.selectBranch(branches[0]))
            dispatch(branchesSlice.allBranches(branches))
            props.onHide()
        }
    }
    useEffect(() => {
        setSelectedBranch(branch)
    }, [branch])
    return (
        <Modal size='lg' {...props} aria-labelledby='contained-modal-title-vcenter' centered>
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>Edit Branch Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-md-6'>
                        <InputContainer name='name' label='Branch Name' required={true}>
                            <input
                                type='text'
                                defaultValue={selectedBranch?.name}
                                onChange={(item) =>
                                    setSelectedBranch({
                                        ...branch,
                                        name: item.target.value,
                                    })
                                }
                            />
                        </InputContainer>
                    </div>
                    <div className='col-md-6'>
                        <InputContainer name='Branch Code' label='Branch Code' required={true}>
                            <input
                                type='text'
                                defaultValue={selectedBranch?.code}
                                onChange={(item) =>
                                    setSelectedBranch({ ...selectedBranch, code: item.target.value })
                                }
                            />
                        </InputContainer>
                    </div>
                    <div className='col-md-12'>
                        <InputContainer name='address' label='Address' required={true}>
                            <input
                                type='text'
                                defaultValue={selectedBranch?.address}
                                onChange={(item) =>
                                    setSelectedBranch({ ...selectedBranch, address: item.target.value })
                                }
                            />
                        </InputContainer>
                    </div>
                </div>
                <div className='d-flex text-align-center mt-3'>
                    <label className='me-2'>Is this a test Center ?</label>
                    <div className='form-check form-switch'>
                        <input
                            className='form-check-input py-2'
                            type='checkbox'
                            id='flexSwitchCheckChecked'
                            defaultChecked={branch?.is_testcenter}
                            onChange={()=>setSelectedBranch({ ...selectedBranch, is_testcenter: !branch.is_testcenter })}
                        />
                    </div>
                </div>
                <div className='text-danger text-center my-2'>
                    {error && 'One or more items is required'}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <DeleteButton name='Service Provider' onClick={deleteSelectedBranch}>
                    Delete
                </DeleteButton>
                <FormButton className='ms-auto' loading={isSaving} onClick={editSelectedBranch}>Save</FormButton>
            </Modal.Footer>
        </Modal>
    )
}
export default EditAndDeleteBranchModal

import React from 'react'
import ReportLayout from './ReportLayout'
import Bookings from '../../components/booking-list/Bookings'

const BookingView = () => {
  return (
    <ReportLayout>
      <Bookings />
    </ReportLayout>
  )
}

export default BookingView
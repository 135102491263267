import { createSlice } from '@reduxjs/toolkit'
import { MultiValue } from 'react-select'

export interface Provider {
  id?: number
  name: string
  desc: string
  code: string
  email: string
  phone: string
  provider_image: string
  is_visible: boolean
  enable_google_sync: boolean
  client_limit: string
  create_username: string
  create_date?: string
  write_username?: string
  write_date?: string
  is_active?: boolean
}

export interface Service {
  name: string
  desc: string
  code: string
  icon: string
  duration: string
  is_visible: boolean
  limit_booking: number
}
export interface Branch {
  address: string
  code: string
  id: number
  is_testcenter: boolean
  name: string
}
export interface ProviderServices {
  name: string
  desc: string
  code: string
  email: string
  phone: string
  provider_image: string
  is_visible: boolean
  enable_google_sync: boolean
  client_limit: number
  services: Service[]
}
interface BranchOptions {
  branch_id: number
  label: string
  value: string
}
interface ServiceOptions {
  service_id: number
  label: string
  value: string
}

const serviceProviders: Provider[] = []
const providerBranches: Branch[] = []
const providerServices: ProviderServices[] = []
const selectedProvider = {} as Provider
const allServices = [] as MultiValue<ServiceOptions>
const serviceOptions = [] as MultiValue<ServiceOptions>
const branchOptions = [] as MultiValue<BranchOptions>
const allBranches = [] as MultiValue<BranchOptions>
const existingBranches = [] as MultiValue<BranchOptions>
const existingServices = [] as MultiValue<ServiceOptions>
const offset = 0
const limit = 100
const initialState = {
  serviceProviders,
  selectedProvider,
  searchInput: '',
  providerBranches,
  providerServices,
  serviceOptions,
  branchOptions,
  allServices,
  allBranches,
  existingBranches,
  existingServices,
  offset,
  limit
}

const serviceProvidersSlice = createSlice({
  name: 'Service Provider',
  initialState,
  reducers: {
    setOffset:(state, {payload})=>{
      state.offset = payload
    },
    setLimit:(state, {payload})=>{
      state.limit = payload
    },
    allProviders: (state, { payload }) => {
      state.serviceProviders = payload
    },
    selectProvider: (state, { payload }) => {
      state.selectedProvider = payload
    },
    setProviderServices: (state, { payload }) => {
      state.providerServices = payload
    },
    setProviderBranches: (state, { payload }) => {
      state.providerBranches = payload
    },
    setAllServices: (state, { payload }) => {
      state.allServices.push(payload)
    },
    setAllBranches: (state, { payload }) => {
      state.allBranches.push(payload)
    },
    setServiceOptions: (state, { payload }) => {
      state.serviceOptions.push(payload)
    },
    setBranchOptions: (state, { payload }) => {
      state.branchOptions.push(payload)
    },
    setExistingBranches: (state, { payload }) => {
      state.existingBranches.push(payload)
    },
    emptyExistingBranches: (state) => {
      state.existingBranches = []
    },
    setExistingServices: (state, { payload }) => {
      state.existingServices.push(payload)
    },
    emptyExistingServices: (state) => {
      state.existingServices = []
    },
    updateBranchOptions: (state, { payload }) => {
      state.branchOptions = payload
    },
    updateServiceOptions: (state, { payload }) => {
      state.serviceOptions = payload
    },
    emptyBranchOptions: (state) => {
      state.branchOptions = []
    },
    emptyServiceOptions: (state) => {
      state.serviceOptions = []
    },
    emptyAllServices: (state) => {
      state.allServices = []
    },
    emptyAllBranches: (state) => {
      state.allBranches = []
    },

    // toggleSchedule: (state, { payload }) => {
    //   state.serviceProviders.map((eachProvider) => {
    //     if (eachProvider.active === true) {
    //       eachProvider.schedules.map((eachSchedule) => {
    //         if (eachSchedule.day === payload.day) {
    //           eachSchedule.active = !eachSchedule.active
    //         }
    //       })
    //     }
    //   })
    // },

    searchProvider: (state, { payload }) => {
      state.searchInput = payload
    },
  },
})
export const {
  allProviders,
  // toggleSchedule,
  selectProvider,
  searchProvider,
  setProviderBranches,
  setProviderServices,
  setServiceOptions,
  setBranchOptions,
  emptyAllServices,
  setAllServices,
  setAllBranches,
  updateBranchOptions,
  updateServiceOptions,
  emptyAllBranches,
  emptyBranchOptions,
  emptyServiceOptions,
  setExistingBranches,
  emptyExistingBranches,
  setExistingServices,
  emptyExistingServices,
  setOffset,
  setLimit
} = serviceProvidersSlice.actions
export default serviceProvidersSlice.reducer

import React from 'react'
import TopNav from './TopNav'
type Props = {
  children: React.ReactNode
  title: string
}

const Layout = ({ title, children }: Props) => {
  return (
    <>
      <title>{title} | EHACare Appointment</title>
      <meta charSet='utf-8' />
      <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      <link rel='shortcut icon' href='/img/favicon-32x32.png' />

      <TopNav />
      <main className='main'>{children}</main>

      <footer className='App-footer'>copyright 2022 EHA Clinics Ltd</footer>
    </>
  )
}
export default Layout

import LoadingSpinner from '@components/loading/LoadingSpinner'
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
// import LoadingSpinner from '@components/loading/LoadingSpinner'

type ConfirmationButtonProps = {
  name: string
  actionMessage: string
  isLoading?: boolean
  className?: string
  buttonColor?: string
  dialogPosition?: string
  disabled?: boolean
  children: React.ReactNode
  onClick?: (ev: any) => void
  textButton?: boolean
  textCase?: string
}

const ConfirmationButton = ({
  name,
  actionMessage,
  isLoading,
  className,
  disabled,
  children,
  onClick,
  buttonColor,
  dialogPosition,
  textCase = 'small',
  textButton = false,
}: ConfirmationButtonProps) => {
  const [showDialog, setActive] = useState(false)

  const [inputValue, setInputValue] = useState(undefined)

  const toggleDialog = () => {
    setActive(!showDialog)
    setInputValue(undefined)
  }

  const checkInput = (evt: any) => {
    setInputValue(evt.target.value.toUpperCase())
  }

  const clickHandler = (ev: any) => {
    toggleDialog()
    onClick && onClick(ev)
  }

  return (
    <div className={'delete-button ' + (className ? className : '')}>
      {textButton ? (
        <div onClick={toggleDialog} className='ms-3 my-1 pointer'>
          {children}
        </div>
      ) : (
        <Button
          onClick={toggleDialog}
          className={`w-100 ${textCase}`}
          disabled={disabled}
          variant={buttonColor ? buttonColor : 'primary'}
        >
          {children}
        </Button>
      )}
      {showDialog && (
        <div
          className={
            dialogPosition ? `confirmation-dialog ${dialogPosition}` : 'confirmation-dialog'
          }
        >
          <div className='d-flex'>
            <span>{actionMessage}</span>
            <i className='eha-close text-primary clickable' onClick={toggleDialog}></i>
          </div>
          <div className='d-grid gap-2 mt-2'>
            <div className='form-group mb-1'>
              <div className='input-wrapper'>
                <input
                  autoFocus
                  type='text'
                  placeholder='type YES'
                  onChange={(evt) => checkInput(evt)}
                ></input>
              </div>
            </div>
            <Button
              variant={buttonColor ? buttonColor : 'danger'}
              disabled={inputValue != 'YES' || disabled}
              onClick={clickHandler}
            >
              {isLoading && (
                <span className='loading-dots'>
                  <LoadingSpinner size='sm'></LoadingSpinner>
                  <LoadingSpinner size='sm'></LoadingSpinner>
                  <LoadingSpinner size='sm'></LoadingSpinner>
                </span>
              )}
              {name}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ConfirmationButton

import SideNavCSS from '@components/side-nav/SideNav.module.css'
import { Link, useLocation } from 'react-router-dom'
import Layout from '@components/layout/Layout'

type Props = {
  children: React.ReactNode
}

const ReportLayout = ({ children }: Props) => {

  const location = useLocation().pathname

  return (
    <Layout title='reorts'>
      <div className='main-bg-container d-flex'>
        <div className='p-4 side-nav pt-5'>
          <div className='label mb-2'>Reports</div>
          <div className='mb-2'>
            <Link
              to='/reports/bookings'
              className={
                location === '/reports/bookings'
                  ? `${SideNavCSS.selectedColor}`
                  : ''
              }
            >
              BOOKINGS
            </Link>{' '}
          </div>

          <div className='mb-2'>
            <Link
              to='/reports/clients'
              className={
                location === '/reports/clients'
                  ? `${SideNavCSS.selectedColor}`
                  : ''
              }
            >
              {' '}
              CLIENTS
            </Link>{' '}
          </div>
        </div>
        <div className='content'>{children}</div>
      </div>
    </Layout>
  )
}
export default ReportLayout

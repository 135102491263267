import { useSelector, useDispatch } from 'react-redux'
import { AppState, AppDispatch } from 'src/store'
import Card from 'react-bootstrap/Card'
import EditAndDeleteBranchModal from './EditAndDeleteBranchModal'
import { useState } from 'react'
import { getAllBranches } from '../../services/branchServices'
import { useQuery } from '@tanstack/react-query'
import { allBranches, selectBranch } from '../../store/BranchesSlice'
import { Alert } from 'react-bootstrap'
import * as texts from '../exceptions/texts';
import LoadingSpinner from '@components/loading/LoadingSpinner'

const BranchDetails = () => {
  const dispatch = useDispatch<AppDispatch>()
  const selectedBranch = useSelector((state: AppState) => state.branches.selectedBranch)
  const [modal, setModal] = useState(false)

  const { isError, isSuccess, isFetching } = useQuery(['allBranches'], () => getAllBranches(), { refetchOnWindowFocus: false, onSuccess: (data) => { dispatch(allBranches(data)); dispatch(selectBranch(data[0])) } });

  return (
    <>
      <EditAndDeleteBranchModal show={modal} onHide={() => setModal(false)} branch={selectedBranch} />
      {isFetching && <LoadingSpinner />}
      {isSuccess &&
        <div className='branch-details pointer'>
          <div className='headline ms-3 mb-2'>Branch Details</div>
          {selectedBranch && Object.keys(selectedBranch).length > 0 ? (
            <Card className='card ' onClick={() => setModal(true)} >
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='label mb-2'>Branch Name</div>
                    <div>{selectedBranch.name}</div>
                    <div className='mt-3'>
                      <div className='label mb-2'>Address</div>
                      <div>{selectedBranch.address}</div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='label mb-2'>Branch Code</div>
                    <div>{selectedBranch.code}</div>
                    <div className='label mb-2 mt-3'>Is this a test Center ?</div>
                    <div>{selectedBranch.is_testcenter ? 'Yes':'No'}</div>
                  </div>

                </div>
              </div>
            </Card>
          ) : (
            <Card>
              <Card.Body>No Branch is available.</Card.Body>
            </Card>
          )}
        </div>
      }

      {isError && <Alert variant="light" className="label m-0 text-danger"><i className="eha-level4 text-danger me-2" />{texts.FETCHING_FAILED}</Alert>}
    </>
  )
}
export default BranchDetails

import { createSlice } from '@reduxjs/toolkit';



type FilterType = {
  filter: {
  branch: string | number;
  service: string | number;
    serviceProvider: string | number;
    status: string;
    createdAt: {
      from: string;
      to: string;
    };
  appointmentDate: {
    from: string;
    to: string;
  }
 }
}

const initialState: FilterType = {
  filter: {
    branch: '',
    service: '',
  status: '',
  serviceProvider: '',
  appointmentDate: {
    from: '',
    to: '',
    },
    createdAt: {
      from: '',
      to: '',
    }
}
}
const bookingsFilterSlice = createSlice({
  name: 'bookingsFilter',
  initialState,
  reducers: {
    setBranchFilter: (state, { payload }) => {
      state.filter.branch = payload
    },
    setServiceFilter: (state, { payload }) => {
      state.filter.service = payload
    },
    setServiceProvider: (state, { payload }) => {
      state.filter.serviceProvider = payload
    },
    setStatus: (state, { payload }) => {
      state.filter.status = payload
    },
    setAppointmentDateFilter: (state, { payload }) => {
      if(payload.placeHolder === 'from') {
        state.filter.appointmentDate['from'] = payload.isoDate
      }else {
        state.filter.appointmentDate['to'] = payload.isoDate
      }
    },
    setCreatedDateFilter: (state, { payload }) => {
      if(payload.placeHolder === 'from') {
        state.filter.createdAt['from'] = payload.isoDate
      }else {
        state.filter.createdAt['to'] = payload.isoDate
      }
    },
    removeCreatedDateFilter: (state, { payload }) => {
      if(payload.placeHolder === 'from') {
        state.filter.createdAt['from'] = ''
      }else {
        state.filter.createdAt['to'] = ''
      }
    },
    removeAppointmentDateFilter: (state, { payload }) => {
      if(payload.placeHolder === 'from') {
        state.filter.appointmentDate['from'] = ''
      }else {
        state.filter.appointmentDate['to'] = ''
      }
    },
    clearState: () => {
      return initialState
    }
  }
})

export const {
  setStatus,
  setBranchFilter,
  setServiceFilter,
  setServiceProvider,
  setAppointmentDateFilter,
  setCreatedDateFilter,
  removeCreatedDateFilter,
  removeAppointmentDateFilter,
  clearState 
} = bookingsFilterSlice.actions
  
export default bookingsFilterSlice.reducer;

import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'src/store'
import { Button } from 'react-bootstrap'
import CreateProviderModal from './CreateProviderModal'
import { searchProvider } from '../../store/ServiceProvidersSlice'

const debounce = (inputFunction: {
  (event: React.ChangeEvent<HTMLInputElement>): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apply?: any
}) => {
  let timer: NodeJS.Timeout | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      inputFunction.apply(this, args)
    }, 500)
  }
}
const SearchProviders = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [modal, setModal] = useState(false)

  const searchInput = useSelector((state: AppState) => state.providers.searchInput)

  const findProvider = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(searchProvider(event.target.value))
  }

  const debounceSearch = useCallback(debounce(findProvider), [])
  return (
    <>
      {modal && <CreateProviderModal show={modal} onHide={() => setModal(false)} />}
      <div className='d-flex w-100 mb-4'>
        <div className='branch-search form-group form-group-dark mb-0 me-3'>
          <div className='d-flex align-items-baseline'>
            <input
              type='text'
              placeholder='Search Provider...'
              onChange={debounceSearch}
              defaultValue={searchInput}
            />
            <i className='me-2 eha-search fs-5 text-primary'></i>
          </div>
        </div>
        <Button variant='white' size='sm' onClick={() => setModal(true)}>
          <i className='eha-plus fs-4 text-primary'></i>
        </Button>
      </div>
    </>
  )
}
export default SearchProviders

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFound_template__ZZTS8 {
  padding: 40px 15px;
  text-align: center;
}
.NotFound_actions__o0Tsf {
  margin-top: 15px;
  margin-bottom: 15px;
}
.NotFound_actions__o0Tsf .NotFound_btn__yuZWu {
  margin-right: 10px;
}

.NotFound_details__hwKjN {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/views/NotFound.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".template {\n  padding: 40px 15px;\n  text-align: center;\n}\n.actions {\n  margin-top: 15px;\n  margin-bottom: 15px;\n}\n.actions .btn {\n  margin-right: 10px;\n}\n\n.details {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"template": `NotFound_template__ZZTS8`,
	"actions": `NotFound_actions__o0Tsf`,
	"btn": `NotFound_btn__yuZWu`,
	"details": `NotFound_details__hwKjN`
};
export default ___CSS_LOADER_EXPORT___;

import SearchBranch from '@components/branches/SearchBranch'
import SelectedBranches from '@components/branches/SelectedBranches'
import BranchDetails from './BranchDetails'
const Branches = () => {
  return (
    <div className='row'>
      <div className="col-xl-4 col-lg-5 d-flex flex-column pe-4  mt-4">
        <div className="headline ml-2 mb-2">Branches</div>
        <SearchBranch />
        <SelectedBranches />
      </div>
      <div className="col-xl-8 col-lg-7 mt-4">
        <BranchDetails />
      </div>
    </div>
  )
}
export default Branches

import { createSlice } from '@reduxjs/toolkit'

export interface Branch{
  id?: number
  name: string,
  code: string,
  create_date?: string
  create_username:string
  address: string,
  is_testcenter: boolean
}

const branches:Branch[] =[]
const selectedBranch ={} as Branch

const initialState = {
  searchInput: '',
  branches,
  selectedBranch
}

const branchesSlice = createSlice({
  name: 'Branches',
  initialState,
  reducers: {
    allBranches: (state, {payload})=>{
      state.branches = payload
    },
    searchBranch: (state, { payload }) => {
      state.searchInput = payload
    },
    selectBranch: (state, { payload }) => {
      state.selectedBranch = payload
    },
  },
})
export const { searchBranch, selectBranch, allBranches } = branchesSlice.actions
export default branchesSlice.reducer

import { SHOW_SATURDAY, HIDE_SATURDAY, SHOW_SUNDAY, HIDE_SUNDAY } from '../../constants/toggleTextConstants';

interface ProviderWeekendToggleButtonProps {
  hideSaturday: boolean;
  hideSunday: boolean;
  onToggleSaturday: () => void;
  onToggleSunday: () => void;
}

const ProviderWeekendToggleButton: React.FC<ProviderWeekendToggleButtonProps> = ({
  hideSaturday,
  hideSunday,
  onToggleSaturday,
  onToggleSunday
}) => {

  const handleSaturdayClick = () => {
    onToggleSaturday(); 
  };

  const handleSundayClick = () => {
    onToggleSunday(); 
  };

  return (
    <div>
      <div className='toggle-button'>
        <div 
          onClick={handleSaturdayClick} 
          className={`toggle ${hideSaturday ? 'toggle-active' : ''}`}
        >
          {hideSaturday ?
            <div className='toggle-left'></div> :
            <div className='toggle-right'></div>} 
        </div>
        <p>{hideSaturday ? SHOW_SATURDAY : HIDE_SATURDAY}</p>
      </div>

      <div className='toggle-button2'>
        <div 
          onClick={handleSundayClick} 
          className={`toggle2 ${hideSunday ? 'toggle-active2' : ''}`}
        >
          {hideSunday ?
            <div className='toggle-left2'></div> :
            <div className='toggle-right2'></div>} 
        </div>
        <p>{hideSunday ? SHOW_SUNDAY : HIDE_SUNDAY}</p>
      </div>
    </div>
  );
};

export default ProviderWeekendToggleButton;

import { useSelector, useDispatch } from 'react-redux'
import { AppState, AppDispatch } from 'src/store'
import Card from 'react-bootstrap/Card'
import EditAndDeleteProviderModal from './EditAndDeleteProviderModal'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import LoadingSpinner from '@components/loading/LoadingSpinner'
import * as providerService from '../../services/providerServices'
import * as providersSlice from '../../store/ServiceProvidersSlice'
import { Alert } from 'react-bootstrap'
import * as texts from '../exceptions/texts'

const ProviderDetails = () => {
    const {offset, limit, selectedProvider} = useSelector((state: AppState) => state.providers)
    const [modal, setModal] = useState(false)
    const dispatch = useDispatch<AppDispatch>()
    const providerServices = useSelector((state: AppState) => state.providers.providerServices)
    const providerBranches = useSelector((state: AppState) => state.providers.providerBranches)
    
    const { isError, isSuccess, isFetching } = useQuery(
        ['providers',offset, limit],
        () => providerService.getAllProviders(offset, limit),
        {
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                dispatch(providersSlice.allProviders(data))
                dispatch(providersSlice.selectProvider(data[0]))
            },
        }, 
    )

    useEffect(() => {
        if (selectedProvider && selectedProvider.id) {
            const getAProvider = async () => {
                const eachProvider = await providerService.getProvider(selectedProvider.id as number)
                dispatch(providersSlice.setProviderServices(eachProvider.services))
                dispatch(providersSlice.setProviderBranches(eachProvider.branches))
            }
            getAProvider()
        }
    }, [selectedProvider && selectedProvider.id])

    const handleClose =()=>{
        dispatch(providersSlice.emptyAllBranches())
        dispatch(providersSlice.emptyAllServices())
        dispatch(providersSlice.emptyBranchOptions())
        dispatch(providersSlice.emptyServiceOptions())
        dispatch(providersSlice.emptyExistingBranches())
        dispatch(providersSlice.emptyExistingServices())
        setModal(false)
    }
    
    return (
        <>
            {modal && <EditAndDeleteProviderModal provider={selectedProvider} show={modal} onHide={handleClose} />}
            {isFetching && <LoadingSpinner />}
            {isSuccess &&
                <div className='branch-details pointer'>
                    <div className='headline ms-3 mb-2'>Service Provider Details</div>
                    {selectedProvider && Object.keys(selectedProvider).length > 0 ? (
                        <div className='card' onClick={() => setModal(true)}>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='label mb-2'>Provider Name</div>
                                        <div>{selectedProvider.name}</div>
                                    </div>
                                        <div className='col-md-6'>
                                            <div className='label mb-2'>Provider Code</div>
                                            <div>{selectedProvider.code}</div>
                                        </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-6'>
                                        <div className='label mb-2'>Email</div>
                                        <div>{selectedProvider.email}</div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='label mb-2'>Phone Number</div>
                                        <div>{selectedProvider.phone}</div>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-6'>
                                        <div className='label mb-2'>Provider Image</div>
                                        <div>{selectedProvider.provider_image}</div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='label mb-2'>Client Limit</div>
                                        <div>{selectedProvider.client_limit}</div>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-6'>
                                        <div className='label mb-2'>Branches</div>
                                        {providerBranches.map(branch => (
                                            <div className='text-box me-1' key={branch.id}>{branch.name}</div>
                                        ))}
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='label mb-2'>Services</div>
                                        {providerServices.map(service => (
                                            <div className='text-box me-1' key={service.name}>{service.name}</div>
                                        ))}
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-12'>
                                        <div className='label mb-2'>Description</div>
                                        <div>{selectedProvider.desc}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                        : (
                            <Card>
                                <Card.Body className='text-center'>No Service Provider is selected.</Card.Body>
                            </Card>
                        )}
                </div>
            }
            {isError && (
                <Alert variant='light' className='label m-0 text-danger'>
                    <i className='eha-level4 text-danger me-2' />
                    {texts.FETCHING_FAILED}
                </Alert>
            )}
        </>
    )
}

export default ProviderDetails

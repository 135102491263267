import { createSlice } from '@reduxjs/toolkit'
import { MultiValue } from 'react-select'
import { TimePickerValue } from 'react-time-picker'

export interface SelectDropdown {
  value: string
  label: string
}

export type Schedule = {
  day: string
  active: boolean
  sunrise: {
    startTime: TimePickerValue
    endTime: TimePickerValue
  }
  sunset: {
    startTime: TimePickerValue
    endTime: TimePickerValue
  }
}
export interface OldService {
  name: SelectDropdown
  icon: string
  description: string
  active: boolean
  serviceCode: string
  duration: string
 // providers: MultiValue<SelectDropdown>
  branches: MultiValue<SelectDropdown>
 // schedules: Schedules[]

}

export interface Service{
  id?: number
  name: string,
  desc: string,
  code: string,
  color: string,
  icon: string,
  duration: string,
  limit_booking: string,
  currency_code?: string,
  is_visible?: boolean,
  is_active?: boolean,
  create_date?: string
  create_username:string
}
export interface ServiceBranches {
  address: string
  code: string
  id: number
  is_testcenter: boolean
  name: string
}
interface BranchOptions {
  branch_id: number
  label: string
  value: string
}

const services: Service[] =[]
const selectedService = <Service>{}
const serviceBranches: ServiceBranches[] = []
const branchOptions = [] as  MultiValue<BranchOptions>
const allBranches = [] as MultiValue<BranchOptions>
const fetchedBranches:ServiceBranches[] = []

const oldServices: OldService[] = [
  {
    name: { value: 'doctor-consultation', label: 'Doctor Consultation' },
    icon: 'eha-doctor',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    active: true,
    serviceCode: 'J12345',
    duration: '60',
    // providers: [
    //   { value: 'hananiya', label: 'Dr Hananiya' },
    //   { value: 'ekeng', label: 'Dr Ekeng' },
    //   { value: 'kyell', label: 'Dr Kyell' },
    // ],
    branches: [
      { value: 'abuja', label: 'Abuja Clinic' },
      { value: 'kano', label: 'Kano Clinic' },
    ],
    // schedules: [
    //   {
    //     branch: 'Abuja',
    //     schedule: [
    //       {
    //         day: 'Monday',
    //         active: true,
    //         sunrise: {
    //           startTime: '08:00',
    //           endTime: '12:00',

    //         },
    //         sunset: {
    //           startTime: '13:00',
    //           endTime: '17:00',

    //         }
    //       },
    //       {
    //         day: 'Tuesday',
    //         active: true,
    //         sunrise: {
    //           startTime: '08:00',
    //           endTime: '12:00',

    //         },
    //         sunset: {
    //           startTime: '13:00',
    //           endTime: '17:00',

    //         }
    //       },
    //     ]
    //   },
    // ]
  },
]

const initialState = {
  searchInput: '',
  services,
  selectedService,
  serviceBranches,
  branchOptions,
  fetchedBranches,
  allBranches
}

const servicesSlice = createSlice({
  name: 'Services',
  initialState,
  reducers: {
    allServices: (state, {payload}) =>{
      state.services = payload
    },
    searchService: (state, { payload }) => {
      state.searchInput = payload
    },
    setBranchOptions:(state, {payload})=>{
      state.branchOptions.push(payload)
    },
    setAllBranches:(state, {payload})=>{
      state.allBranches.push(payload)
    },
    emptyAllBranches:(state)=>{
      state.allBranches = []
    },
    updateBranchOptions: (state,{payload})=>{
      state.branchOptions = payload
    },
    emptyBranchOptions: (state)=>{
      state.branchOptions = []
    },
    setFetchedBranches:(state,{payload})=>{
      state.fetchedBranches = payload
    },
    emptyFetchedBranches:(state)=>{
      state.fetchedBranches = []
    },
    selectService: (state, { payload }) => {
      state.selectedService = payload
    },
    setServiceBranches:(state, {payload})=>{
      state.serviceBranches = payload
    }
    
    // toggleSchedule:(state,{payload})=>{
    //   state.services.map((eachService) => {
    //     if (eachService.active === true) {
    //       eachService.schedules.map((eachSchedule) => {
    //         if(eachSchedule.branch === 'Abuja'){
    //             eachSchedule.schedule.map(each =>{
    //               if(each.day === payload.day){
    //                 each.active = !each.active
    //               }
    //             })
    //         }
    //         if(eachSchedule.branch === 'Kano'){
    //             eachSchedule.schedule.map(each =>{
    //               if(each.day === payload.day){
    //                 each.active = !each.active
    //               }
    //             })
    //         }
            
            
    //       })
    //     }
    //   })
    // },
  },
})
export const {
  searchService,
  selectService,
  allServices,
  setServiceBranches,
  setBranchOptions,
  emptyBranchOptions,
  updateBranchOptions,
  setFetchedBranches,
  emptyFetchedBranches,
  setAllBranches,
  emptyAllBranches
  // toggleSchedule
} = servicesSlice.actions
export default servicesSlice.reducer

export enum TimeSlot {
  AllDay = '00:00-23:59',
  Morning = '08:00-12:00',
  Afternoon = '12:00-17:00',
  Evening = '17:00-22:00',
  Night = '22:00-08:00',
}

export enum TimeLabels {
  MORNING = 'Morning',
  AFTERNOON = 'Afternoon',
  NIGHT = 'Night',
  FULL_DAY = 'Full Day',
  OFF = 'Off',
}

export enum TimeValues {
  MIDNIGHT = '00:00',
  EARLY_MORNING = '07:59',
  MORNING_8AM = '08:00',
  AFTERNOON_3PM = '15:00',
  NOON_1259PM = '12:59',
  AFTERNOON_1PM = '13:00',
  EVENING_8PM = '20:00',
  EVENING_1959 = '19:59',
  END_OF_DAY = '23:59',
}

export enum SlotCombination {
  MORNING = 'Morning',
  AFTERNOON = 'Afternoon',
  NIGHT = 'Night',
  FULL_DAY = 'Full Day',
}

export enum SlotLabels {
  MORNING_FIRST = 'Morning First Slot',
  MORNING_SECOND = 'Morning Second Slot',
  AFTERNOON_FIRST = 'Afternoon First Slot',
  AFTERNOON_SECOND = 'Afternoon Second Slot',
  NIGHT_FIRST = 'Night First Slot',
  NIGHT_SECOND = 'Night Second Slot',
  FULL_DAY_FIRST = 'Full Day First Slot',
  FULL_DAY_SECOND = 'Full Day Second Slot',
}

import { useQuery } from '@tanstack/react-query'
import React, { ReactNode, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getBookingTokenValidation } from '../services/bookings'
import BookingFallback from '@components/update-booking/BookingFallback'

type PatientAuthProps = {
  children: ReactNode
}
const PatientAuth = ({ children }: PatientAuthProps) => {
  const { bookingId, token } = useParams()
  const [isVerified, setIsVerified] = useState<boolean | null>(null)

  const getTokenById = async () => {
    try {
      if (token && bookingId) {
        const res = await getBookingTokenValidation(Number(bookingId), token)
        if (res.validated === true) {
          setIsVerified(true)
        } else {
          setIsVerified(false)
        }
        return res
      }
    } catch (error) {
      setIsVerified(false)
    }
  }

  const { isLoading } = useQuery(['get-token-validation', token, bookingId], () => getTokenById(), {
    refetchOnWindowFocus: false,
  })

  if (isLoading || isVerified === null) {
    return <div>Loading...</div>
  }

  if (isVerified === false) {
    return <BookingFallback />
  } else if (isVerified === true) {
    return <div>{children}</div>
  } else return <></>
}

export default PatientAuth

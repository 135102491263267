import React from 'react'
import ReportLayout from './ReportLayout'
import Clients from '../../components/clients/Clients'

const ClientView = () => {
  return (
    <ReportLayout>
      <Clients />
    </ReportLayout>
  )
}

export default ClientView
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { AppState } from '../../store';
import UpdateBookingDetails from './UpdateBookingDetails';
import RescheduleCalendar from './RescheduleCalendar';
import ConfirmRescheduleModal from './ConfirmRescheduleModal';
import {
  setShowRescheduleModal,
  setShowCancelModal,
  setShowConfirmRescheduleModal,
} from '../../store/UpdateBookingSlice';

interface RescheduleAppointmentModalProps {
  show: boolean;
  onHide: () => void;
}

const ModalHeader: React.FC<{ onHide: () => void }> = ({ onHide }) => (
  <Modal.Header>
    <div className="ca-exit cancel-exit">
      <button onClick={onHide} className="eha-close" />
    </div>
  </Modal.Header>
);

interface RescheduleContentProps {
  onCancelClick: () => void;
  onRescheduleClick: () => void;
  onHide: () => void;
  disableRescheduleButton: boolean;
  disableAllButtons: boolean; 
}

const RescheduleContent: React.FC<RescheduleContentProps> = ({ onCancelClick, onRescheduleClick, onHide, disableRescheduleButton, disableAllButtons }) => (
  <>
    <div className="ca-buttons-section">
      <Button variant="danger" className="ca-button" onClick={onCancelClick} disabled={disableAllButtons}>
        CANCEL BOOKING
      </Button>
      <div className="ca-buttons">
        <Button variant="light" className="ca-button2" onClick={onHide} disabled={disableAllButtons}>
          DISMISS
        </Button>
        <Button variant="secondary" className="ca-button" onClick={onRescheduleClick} disabled={disableRescheduleButton || disableAllButtons}>
          RESCHEDULE
        </Button>
      </div>
    </div>
  </>
);

const RescheduleAppointmentModal: React.FC<RescheduleAppointmentModalProps> = ({ show, onHide }) => {
  const { booking, showConfirmRescheduleModal } = useSelector((state: AppState) => state.updateBooking);
  const dispatch = useDispatch();
  const [selectedDateTime, setSelectedDateTime] = useState<string>('');
  const [error, setError] = useState<string | null>(null);

  const handleReschedule = () => {
    if (!selectedDateTime) {
      setError('Date and time must be selected for rescheduling...');
      return;
    }
    setError(null);
    dispatch(setShowConfirmRescheduleModal(true));
  };

  const handleCancelClick = () => {
    dispatch(setShowRescheduleModal(false));
    dispatch(setShowCancelModal(true));
  };

  const handleConfirmHide = () => {
    dispatch(setShowConfirmRescheduleModal(false));
  };

  return (
    <>
      <Modal
        size="xl"
        show={show}
        className="update-modal-main"
        backdrop="static"
        enforceFocus={false}
        backdropClassName="front"
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        centered
        onHide={onHide}
      >
        <ModalHeader onHide={onHide} />
        <Modal.Body>
          <UpdateBookingDetails type="modal">
            {error && <div className='error-message'>{error}</div>}
            {booking?.provider_id && (
              <RescheduleCalendar setSelectedDateTime={setSelectedDateTime} providerId={booking.provider_id} />
            )}
            <RescheduleContent
              onRescheduleClick={handleReschedule}
              onHide={onHide}
              disableRescheduleButton={!selectedDateTime}
              onCancelClick={handleCancelClick}
              disableAllButtons={showConfirmRescheduleModal}
            />
          </UpdateBookingDetails>
        </Modal.Body>
      </Modal>

      <ConfirmRescheduleModal
        show={showConfirmRescheduleModal}
        onHide={handleConfirmHide}
        selectedDateTime={selectedDateTime}
      />
    </>
  );
};

export default RescheduleAppointmentModal;

import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';


interface DeleteButtonProps {
  name: string,
  className?: string,
  disabled?: boolean,
  children: React.ReactNode,
  onClick?:() => void
}

const DeleteButton = ({name, className, disabled, children, onClick} : DeleteButtonProps) => {

  const [showDialog, setActive] = useState(false);

  const [inputValue, setInputValue] = useState('');

  const toggleDialog = () => {
    setActive(!showDialog)
    setInputValue('')
  };

  const checkInput = (evt:React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(evt.target.value.toUpperCase())
  }

  return (
  <div className={'delete-button ' + (className ? className : '')}>
      <Button variant="danger" onClick={toggleDialog} className="w-100" disabled={disabled}>
        {children}
      </Button>
    {showDialog &&
      <div className="confirmation-dialog">
        <div className="d-flex">
          <span>Are you sure you want to delete this <b>{name}</b>?</span>
          <i className="eha-close text-primary clickable" onClick={toggleDialog}></i>
        </div>
        <div className="d-grid gap-2 mt-2">
          <div className="form-group mb-1">
            <div className="input-wrapper">
              <input autoFocus type="text" placeholder="type YES" onChange={evt => checkInput(evt)}></input>
            </div>
          </div>
          <Button variant="danger" disabled={inputValue != 'YES'} onClick={onClick}>
            {inputValue && 
              inputValue + ', '
            } delete
          </Button>
        </div>

      </div>
    }
  </div>
  )
}

export default DeleteButton;

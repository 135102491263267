import React from 'react'
import Layout from './Layout'

const BookingFallback = () => {
  return (
    <Layout title='Cancel Appointments'>
      <div className='booking-fallback'>
        <div className='booking-error-text'>Error</div>
        <div className='booking-error-image-footer'>
          <img className='' src='/img/BookingFallbackSVG.svg' />
          <div>Sorry, access to this page has expired</div>
        </div>
      </div>
    </Layout>
  )
}

export default BookingFallback

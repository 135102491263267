import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getBookingById } from '../../services/bookings';
import CompletedBookingModal from './CompletedBookingModal';
import UpdateBookingDetails from './UpdateBookingDetails';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import {
  setBooking,
  setShowCancelConfirmationModal,
  setShowCancelledBookingModal,
  setShowCompletedModal,
  setShowCancelModal,
  setShowRescheduleModal,
  setShowRescheduleConfirmationModal,
} from '../../store/UpdateBookingSlice';
import CancelAppointmentConfirmationModal from './CancelAppointmentConfirmationModal';
import CancelledBookingModal from './CancelledBookingModal';
import CancelAppointmentModal from './CancelAppointmentModal';
import RescheduleBookingModal from './RescheduleAppointmentModal';
import RescheduleAppointmentConfirmationModal from './RescheduleAppointmentConfirmationModal';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import Layout from './Layout';

const UpdateBooking: React.FC = () => {
  const {
    showCancelConfirmationModal,
    showCompletedModal,
    showCancelledBookingModal,
    booking,
    showCancelModal,
    showRescheduleModal,
    showRescheduleConfirmationModal,
  } = useSelector((state: AppState) => state.updateBooking);
  const dispatch = useDispatch();
  const { bookingId } = useParams<{ bookingId: string }>();
  const [loading, setLoading] = useState(false);

  const bookingRef = useRef(1);

  const getBookingsQueryFunc = async () => {
    if (bookingId) {
      setLoading(true);
      const data = await getBookingById(Number(bookingId));
      dispatch(setBooking(data));
      setLoading(false);
      return data;
    }
  };

  useQuery(['get-booking', bookingId], getBookingsQueryFunc, {
    onSuccess: (data) => {
      if (data.status === 'completed') {
        dispatch(setShowCompletedModal(true));
      }
    },
    refetchOnWindowFocus: false,
  });

  const handleShowCancelConfirmationModal = async () => {
    dispatch(setShowCancelConfirmationModal(false));
    dispatch(setShowCancelledBookingModal(false));
  };

  const handleRescheduleModal = () => {
    dispatch(setShowRescheduleModal(true));
  };

  useEffect(() => {
    if (booking) {
      if (booking.status === 'cancelled' && bookingRef.current === 1) {
        dispatch(setShowCancelledBookingModal(true));
      }
      bookingRef.current = 2;
    }
  }, [booking]);

  const updateCards = [
    {
      title: 'Cancel your appointment',
      desc: 'Cancel your appointment to stop it from happening. Cancelling your appointment cannot be undone. Please make sure you have to cancel.',
      type: 'cancel',
      action: () => {
        dispatch(setShowCancelModal(true));
      },
    },
    {
      title: 'Reschedule your appointment',
      desc: 'Rescheduling your appointment changes the date from the current to your most preferred date. This action cannot be undone, however, you can modify the date to the next available date.',
      type: 'reschedule',
      action: handleRescheduleModal,
    },
  ];

  if (loading || !booking) {
    return (
      <div className='loading-booking'>
        <LoadingSpinner />
      </div>
    );
  } else
    return (
      <>
        {showCancelConfirmationModal && (
          <CancelAppointmentConfirmationModal
            show={showCancelConfirmationModal}
            action={handleShowCancelConfirmationModal}
          />
        )}
        {showCompletedModal && (
          <CompletedBookingModal
            show={showCompletedModal}
            action={() => {
              dispatch(setShowCompletedModal(false));
            }}
          />
        )}
        {showCancelledBookingModal && (
          <CancelledBookingModal
            show={showCancelledBookingModal}
            action={() => {
              dispatch(setShowCancelledBookingModal(false));
            }}
          />
        )}
        {showRescheduleModal && (
          <RescheduleBookingModal
            show={showRescheduleModal}
            onHide={() => dispatch(setShowRescheduleModal(false))}
          />
        )}
        {showRescheduleConfirmationModal && (
          <RescheduleAppointmentConfirmationModal
            show={showRescheduleConfirmationModal}
            action={() => dispatch(setShowRescheduleConfirmationModal(false))}
          />
        )}

        {showCancelModal && (
          <CancelAppointmentModal
            show={showCancelModal}
            action={() => {
              dispatch(setShowCancelModal(false));
            }}
            onRescheduleClick={() => {
              dispatch(setShowRescheduleModal(true));
              dispatch(setShowCancelModal(false));
            }}
          />
        )}
        <Layout title='Cancel Appointments'>
          <UpdateBookingDetails type='page'>
            {booking && booking?.status === 'confirmed' && (
              <div className='update-booking-cards'>
                {updateCards.map((item, index) => (
                  <div key={index} className='update-booking-card'>
                    <div className='title-desc'>
                      <h4>{item.title}</h4>
                      <div className='card-desc'>{item.desc}</div>
                    </div>
                    <Button
                      variant={item.type === 'cancel' ? 'danger' : 'secondary'}
                      onClick={item.action}
                    >
                      {item.type === 'cancel' ? 'CANCEL BOOKING' : 'RESCHEDULE BOOKING'}
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </UpdateBookingDetails>
        </Layout>
      </>
    );
};

export default UpdateBooking;

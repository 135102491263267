import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppState, AppDispatch } from 'src/store'
import Card from 'react-bootstrap/Card'
import EditAndDeleteModal from './EditAndDeleteServiceModal'
import * as serviceServices from '../../services/serviceServices'
import * as servicesSlice from '../../store/ServicesSlice'
import { useQuery } from '@tanstack/react-query'
import LoadingSpinner from '@components/loading/LoadingSpinner'
import { Alert } from 'react-bootstrap'
import * as texts from '../exceptions/texts'

const ServiceDetails = () => {
  const selectedService = useSelector((state: AppState) => state.services.selectedService)
  const [modal, setModal] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const serviceBranches = useSelector((state: AppState) => state.services.serviceBranches)

  const { isError, isSuccess, isFetching } = useQuery(
    ['allServices'],
    () => serviceServices.getAllServices(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        dispatch(servicesSlice.allServices(data))
        dispatch(servicesSlice.selectService(data[0]))
      },
    },
  )
  useEffect(() => {
    if (selectedService && selectedService.id) {
      const getAService = async () => {
        const eachService = await serviceServices.getService(selectedService.id as number)
        dispatch(servicesSlice.setServiceBranches(eachService?.branches))
      }
      getAService()
    }
  }, [selectedService && selectedService.id])
  const handleClose = () => {
    dispatch(servicesSlice.emptyBranchOptions())
    dispatch(servicesSlice.emptyFetchedBranches())
    dispatch(servicesSlice.emptyAllBranches())
    setModal(false)
  }

  return (
    <>
      {modal && selectedService && Object.keys(selectedService).length > 0 && (
        <EditAndDeleteModal show={modal} onHide={handleClose} service={selectedService} />
      )}
      {isFetching && <LoadingSpinner />}
      {isSuccess && (
        <div className='branch-details'>
          <div className='headline ms-3 mb-2'>Service Details</div>
          {selectedService && Object.keys(selectedService).length > 0 ? (
            <div className='card pointer' onClick={() => setModal(true)}>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='label mb-2'>Service Name</div>
                    <div>{selectedService?.name}</div>
                  </div>
                  <div className='col-md-6'>
                    <div className='label mb-2'>Service Code</div>
                    <div className=''>{selectedService.code}</div>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <div className='label mb-2'>Service Duration</div>
                    <div>{selectedService.duration} min</div>
                  </div>
                  <div className='col-md-6'>
                    <div className='label mb-2'>Limit Bookings</div>
                    <div>{selectedService.limit_booking}</div>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <div className='label mb-2'>Branches</div>
                    {serviceBranches.map((branch) => (
                      <div className='text-box me-1' key={branch.id}>
                        {branch.name}{' '}
                      </div>
                    ))}
                  </div>
                  <div className='col-md-6'>
                    <div className='label mb-2'>Color Code</div>
                    {selectedService?.color}
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <div className='label mb-2'>Description</div>
                    <div>{selectedService.desc}</div>
                  </div>
                  <div className='col-md-6'>
                    <div className='label mb-2'>Is Visible</div>
                    <div>{selectedService.is_visible ? 'Yes' : 'No'}</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Card>
              <Card.Body>No Service is selected.</Card.Body>
            </Card>
          )}
        </div>
      )}
      {isError && (
        <Alert variant='light' className='label m-0 text-danger'>
          <i className='eha-level4 text-danger me-2' />
          {texts.FETCHING_FAILED}
        </Alert>
      )}
    </>
  )
}
export default ServiceDetails

import React, { Dispatch, SetStateAction } from 'react'
import { Modal } from 'react-bootstrap'

const CompletedBookingModal = ({
  show,
  action,
}: {
  show: boolean
  action: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <Modal
      size='lg'
      show={show}
      className='ca-modal-main'
      backdrop='static'
      enforceFocus={false}
      backdropClassName='front'
      dialogClassName='modal-min-90'
      aria-labelledby='contained-modal-title-vcenter'
      scrollable={true}
      centered
    >
      <Modal.Header>
        <div className='ca-exit'>
          <button
            onClick={() => {
              action(false)
            }}
            className='eha-close'
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='ca-modal'>
          <div className='ca-calendar-x'>
            <img src='/img/BookingErrorModalIcon.svg' />
          </div>

          <div className='ca-modal-title'>Booking Already Complete</div>

          <div className='ca-note ca-note-booking'>
            Your booking is already confirmed and cannot be cancelled at this time. We apologise for
            any inconvenience this may cause. If you have any concerns please contact our customer
            support team at help@eha.ng
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CompletedBookingModal

import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'
import {searchBranch} from '../../store/BranchesSlice'
import { Button } from 'react-bootstrap'
import CreateBranchModal from './CreateBranchModal'


const debounce = (inputFunction: {
  (event: React.ChangeEvent<HTMLInputElement>): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apply?: any
}) => {
  let timer: NodeJS.Timeout | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      inputFunction.apply(this, args)
    }, 500)
  }
}
const SearchBranch = () => {
  const dispatch = useDispatch<AppDispatch>();
  const findBranch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const branchInfoInput = event?.target?.value
    const targetBranch = branchInfoInput.toLowerCase()
      dispatch(searchBranch(targetBranch))
  }
  const [modal, setModal] = useState(false)
  const debounceSearch = useCallback(debounce(findBranch), [])
  return (
    <>
    <CreateBranchModal show={modal} onHide={()=> setModal(false)} />
    <div className="d-flex w-100 mb-4">
      <div className='branch-search form-group form-group-dark mb-0 me-3'>
        <div className='d-flex align-items-baseline'>
          <input type='text' placeholder='Search Branch...' onChange={debounceSearch} />
          <i className='me-2 eha-search fs-5 text-primary'></i>
        </div>
      </div>
      <Button variant='white' size='sm' onClick={() => setModal(true)}>
          <i className='eha-plus fs-4 text-primary'></i>
      </Button>
    </div>
    </>
  )
}
export default SearchBranch

import * as React from 'react'
import Slider from '@mui/material/Slider'
import { alpha, styled } from '@mui/material'
import Switch from '@mui/material/Switch'
import { marks } from '@utils/providerSlotUI'

type SliderProps = {
  data: {
    value1: number[]
    value2: number[]
    day: string,
    checked: boolean
  }
  updateDaySlots: (day: string, checked: boolean, type?: string, value?: number[]) => void
}

export default function SlotSlider({ data, updateDaySlots }: SliderProps) {
  const emptyCells = Array(24).fill('')

  const checked = data.checked

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   updateDaySlots(data.day,event.target.checked)
  }

  const [timeRange1, setTimeRange1] = React.useState('')
  const [timeRangeStyle1, setTimeRangeStyle1] = React.useState<{
    left?: string
    right?: string
  }>()

  const [timeRange2, setTimeRange2] = React.useState('')
  const [timeRangeStyle2, setTimeRangeStyle2] = React.useState<{
    left?: string
    right?: string
  }>()

  const minDistance = 100 / 12

  const handleChange1 = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return
    }

    let firstArrayItem = data.value1[0]
    let secondArrayItem = data.value1[1]

    if (activeThumb === 0) {
      firstArrayItem =
        newValue[0] / minDistance !== secondArrayItem ? newValue[0] / minDistance : firstArrayItem
    } else {
      secondArrayItem =
        newValue[1] / minDistance !== firstArrayItem ? newValue[1] / minDistance : secondArrayItem
    }

    const value = [firstArrayItem, secondArrayItem]
    updateDaySlots(data.day, data.checked,'value1', value)
  }

  const handleChange2 = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return
    }

    let firstArrayItem = data.value2[0]
    let secondArrayItem = data.value2[1]

    if (activeThumb === 0) {
      firstArrayItem =
        newValue[0] / minDistance !== secondArrayItem ? newValue[0] / minDistance : firstArrayItem
    } else {
      secondArrayItem =
        newValue[1] / minDistance !== firstArrayItem ? newValue[1] / minDistance : secondArrayItem
    }

    const value = [firstArrayItem, secondArrayItem]
    updateDaySlots(data.day, data.checked,'value2', value)
  }

  // Should fire whenever value1 changes
  React.useEffect(() => {
    const value = value1
    const newValue = []
    for (let i = 0; i < 2; i++) {
      const time = value[i] / (100 / 12)
      if (time === 0) {
        newValue[i] = '12am'
      } else if (time === 12) {
        newValue[i] = '12pm'
      } else {
        newValue[i] = `${Math.round(time)}am`
      }
    }

    setTimeRange1(`${newValue[0]} - ${newValue[1]}`)

    // Style the time range tag
    const firstValue = Math.round(value[0] / (100 / 12))

    if (firstValue < 10) {
      setTimeRangeStyle1({ left: `${(100 / 24) * firstValue}%` })
    } else {
      setTimeRangeStyle1({ right: '50%' })
    }
  }, [data.value1])

  React.useEffect(() => {
    const value = value2
    const newValue = []
    for (let i = 0; i < 2; i++) {
      const time = value[i] / (100 / 12)
      if (time === 0) {
        newValue[i] = '12pm'
      } else if (time === 12) {
        newValue[i] = '12am'
      } else {
        newValue[i] = `${Math.round(time)}pm`
      }
    }

    setTimeRange2(`${newValue[0]} - ${newValue[1]}`)

    // Style the time range tag
    const firstValue = Math.round(value[0] / (100 / 12))

    if (firstValue < 10) {
      setTimeRangeStyle2({ left: `${(100 / 24) * firstValue + 50}%` })
    } else {
      setTimeRangeStyle2({ right: '0%' })
    }
  }, [data.value2])

  const value1 = [minDistance * data.value1[0], minDistance * data.value1[1]]
  const value2 = [minDistance * data.value2[0], minDistance * data.value2[1]]

  return (
    <div className='slider-body-row'>
      <div className='slider-day-switch'>
        <div className='actual-slider-day-switch'>
          <div>
            <DaySwitch
              checked={checked}
              onChange={handleChange}
              size='small'
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
          <div className='slider-day-name'>{data.day}</div>
        </div>
      </div>
      <div className='slider-body-cells'>
        <div className='time-range-tag1' style={timeRangeStyle1}>
          {checked ? timeRange1 : null}
        </div>
        <div className='time-range-tag1' style={timeRangeStyle2}>
          {checked ? timeRange2 : null}
        </div>
        {emptyCells.map((item, index) => {
          return <div key={index} className='slider-body-cell'></div>
        })}
        <div className='slider-body'>
          <div className={`range-slider ${!checked ? 'center-slider' : ''}`}>
            {!checked ? <div className='slider-unavailable'>Unavailable</div> : null}
            {checked ? (
              <MainSlider
                getAriaLabel={() => 'Minimum distance'}
                value={value1}
                onChange={handleChange1}
                valueLabelDisplay='off'
                marks={marks}
                step={null}
                disableSwap
              />
            ) : null}
            {checked ? (
              <MainSlider
                getAriaLabel={() => 'Minimum distance'}
                value={value2}
                onChange={handleChange2}
                valueLabelDisplay='off'
                marks={marks}
                step={null}
                disableSwap
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

const MainSlider = styled(Slider)(() => ({
  color: '#5b74c6',
  height: 5,
  padding: '0px',

  '& .MuiSlider-thumb': {
    height: 11,
    width: 11,
  },

  '& .MuiSlider-rail': {
    backgroundColor: 'transparent',
  },
}))

const DaySwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#5b74c6',
    '&:hover': {
      backgroundColor: alpha('#5b74c6', 0.4),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#5b74c6',
    opacity: 1,
    height: '100%',
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: 'white',
    border: '1px solid #5b74c6',
  },
}))

/**
 * Note: Throughout this slider, 100/12 and 100/24 is used because MUI slider API is such that the parts must be a fraction of 100. ie expressed as percentages. Hence to divide a slider into 12 equal parts 1-12, each part must be 100/12
 */
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'
import { Button } from 'react-bootstrap'
import { searchService } from '../../store/ServicesSlice'
import CreateServiceModal from './CreateServiceModal'
const debounce = (inputFunction: {
  (event: React.ChangeEvent<HTMLInputElement>): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apply?: any
}) => {
  let timer: NodeJS.Timeout | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (...args: any) => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      inputFunction.apply(this, args)
    }, 500)
  }
}

const SearchService = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [modal, setModal] = useState(false)
  const findService = (event: React.ChangeEvent<HTMLInputElement>) => {
    const serviceInfoInput = event?.target?.value
    const targetService = serviceInfoInput.toLowerCase()
    dispatch(searchService(targetService))
  }
  const debounceSearch = useCallback(debounce(findService), [])
  return (
    <>
    <CreateServiceModal show={modal}  onHide={() => setModal(false)} />
    <div className='d-flex w-100 mb-4'>
      <div className='branch-search form-group form-group-dark mb-0 me-3'>
        <div className='d-flex align-items-baseline'>
          <input type='text' placeholder='Search Service...' onChange={debounceSearch} />
          <i className='me-2 eha-search fs-5 text-primary'></i>
        </div>
      </div>
      <Button variant='white' size='sm' onClick={()=> setModal(true)}>
        <i className='eha-plus fs-4 text-primary'></i>
      </Button>
    </div>
    </>
  )
}
export default SearchService

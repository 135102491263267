export const BOOKINGS_TABLE_PAGE_SIZE = 100;
export const MORNING = 'morning';
export const AFTERNOON = 'afternoon';
export const MONDAY = 'MONDAY';
export const TUESDAY = 'TUESDAY';
export const WEDNESDAY = 'WEDNESDAY';
export const THURSDAY = 'THURSDAY';
export const FRIDAY = 'FRIDAY';
export const SATURDAY = 'SATURDAY';
export const SUNDAY = 'SUNDAY';
export const CANCELLED = 'cancelled';
export const COMPLETED = 'completed'; 
export const NOT_FOUND = 'Resource not found'; // 404 error
export const NOT_AUTHORIZED = 'Sorry there is authorization problem accessing the demography server'; // 401 error
export const FORBIDDEN = 'Sorry, the request is forbidden'; // 403 error
export const BAD_REQUEST = 'Sorry, this is a bad request.'; // 400 error
export const TIME_OUT = 'Sorry demography server request timeout'; // 408 error
export const SERVER_DOWN = 'Sorry the demography server is down'; // 500 error
export const PATIENT_NOT_FOUND = 'No matching patient found!';
export const UNEXPECTED_ERROR = 'Unexpected Error occured';
export const PATIENT_DATA_FETCHING_ERROR = 'Couldn\'t fetch patient data.';
export const PATIENT_EXIST = 'Patient already exist!';
export const FORM_RENDERING_FAIL = 'Form could not be rendered!';
export const ERROR_GETTING_EHR_ID = 'Error getting patient\'s ehr id';
export const FETCHING_FAILED = 'An Error occurred while fetching data';
 
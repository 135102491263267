import Layout from '@components/layout/Layout'
import { FC, useEffect  } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import Branches from '@components/branches'
import SideNav from '@components/side-nav'

export const Home: FC = () => {

  const { keycloak, initialized} = useKeycloak()

  if(!initialized){
    return (<p>Loading ...</p>)
  }

  useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.login()
    }
  }, [keycloak])

  return (
    <>
      <Layout title='Home'>
        <div className='main-bg-container'>
          <SideNav>
            <Branches />
          </SideNav>
        </div>
      </Layout>
    </>
  )
}

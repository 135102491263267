import SideNavCSS from './SideNav.module.css'
import { Link, useLocation } from 'react-router-dom'

type Props = {
  children: React.ReactNode
}

const SideNav = ({ children }: Props) => {
  
  const location = useLocation().pathname

  return (
    <div className='main-bg-container d-flex'>
      <div className='p-4 side-nav pt-5'>
        <div className='mb-2'>
          <Link
            className={
              location === '/'
                ? `${SideNavCSS.selectedColor}`
                : ''
            }
            to='/'
          >
            BRANCHES
          </Link>{' '}
        </div>

        <div className='mb-2'>
          <Link
            to='/services'
            className={
              location === '/services'
                ? `${SideNavCSS.selectedColor}`
                : ''
            }
          >
            {' '}
            SERVICES
          </Link>{' '}
        </div>
        <div className='mb-2'>
          <Link
            to='/providers'
            className={
              location === '/providers'
                ? `${SideNavCSS.selectedColor}`
                : ''
            }
          >
            {' '}
            SERVICE PROVIDERS
          </Link>{' '}
        </div>
      </div>
      <div className='p-4 content'>{children}</div>
    </div>
  )
}
export default SideNav

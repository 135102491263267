import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setShowProviderCalendarModal } from '../../store/UpdateBookingSlice';
import ProviderWeekendToggleButton from '../service-providers/ProviderWeekendToggleButton';
import ProviderCalendar from '../service-providers/ProviderCalendar';
import { getProvider } from '../../services/providerServices';

interface ProviderCalendarModalProps {
  show: boolean;
  onHide: () => void;
  providerId: number;
  slotId: number;
}

const ProviderCalendarModal: React.FC<ProviderCalendarModalProps> = ({
  show,
  onHide,
  providerId,
  slotId,
}) => {
  const dispatch = useDispatch();
  const [hideSaturday, setHideSaturday] = useState(false);
  const [hideSunday, setHideSunday] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [providerName, setProviderName] = useState<string>('');
  useEffect(() => {
    const fetchProviderName = async () => {
      try {
        const providerDetails = await getProvider(providerId);
        setProviderName(providerDetails.name);

        setError(null);
      } catch (error) {
        setError('Oops, Failed to fetch provider\'s name. Please contact admin.');
      }
    };

    if (providerId) {
      fetchProviderName();
    }
  }, [providerId]);

  const handleHide = () => {
    dispatch(setShowProviderCalendarModal(false));
    onHide();
  };

  const handleToggleSaturday = () => setHideSaturday((prev) => !prev);
  const handleToggleSunday = () => setHideSunday((prev) => !prev);

  return (
    <Modal
      size='xl'
      show={show}
      backdrop='static'
      enforceFocus={false}
      backdropClassName='front'
      aria-labelledby='contained-modal-title-vcenter'
      scrollable
      centered
      onHide={handleHide}
      dialogClassName='custom-modal-size'
    >
      <Modal.Header closeButton>
        <Modal.Title className='modal-title'>Set Unavailability for {providerName}</Modal.Title>
      </Modal.Header>
      <div className='toggle-container'>
        <p>Toggle to hide weekends:</p>
        <ProviderWeekendToggleButton
          hideSaturday={hideSaturday}
          hideSunday={hideSunday}
          onToggleSaturday={handleToggleSaturday}
          onToggleSunday={handleToggleSunday}
        />
      </div>
      <Modal.Body>
        {error && <div className='alert alert-danger'>{error}</div>}
        <ProviderCalendar
          hideSaturday={hideSaturday}
          hideSunday={hideSunday}
          providerId={providerId}
          slotId={slotId}
          dateFrom='string'
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='light' onClick={handleHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProviderCalendarModal;

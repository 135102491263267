import ServiceDetails from './ServiceDetails'
import SearchService from './SearchService'
import SelectedServices from './SelectedServices'
import Layout from '@components/layout/Layout'
import SideNav from '@components/side-nav'
// import ServiceSchedule from './ServiceSchedule'

const Services = () => {
  return (
    <Layout title='services'>
      <SideNav>
        <div className='row'>
          <div className='col-xl-4 col-lg-5 d-flex flex-column pe-4 mt-4'>
            <div className='headline ml-2 mb-2'>Services</div>
            <SearchService />
            <SelectedServices />
          </div>
          <div className='col-xl-8 col-lg-7 mt-4'>
            <ServiceDetails />
            {/* <ServiceSchedule /> */}
          </div>
        </div>
      </SideNav>
    </Layout>
  )
}
export default Services

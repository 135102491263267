/* eslint-disable camelcase */
import { IScheduleObject } from '@components/service-providers/ServiceProviderSchedule';
import { MORNING, AFTERNOON, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY } from '../constants/constant';

export const headerTimeLabels = [
  '12am',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12pm',
  'x',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  // '12am',
]

export const marks = [
  {
    value: 0,
    label: '',
  },
  {
    value: (100 / 12) * 1,
    label: '',
  },
  {
    value: (100 / 12) * 2,
    label: '',
  },
  {
    value: (100 / 12) * 3,
    label: '',
  },
  {
    value: (100 / 12) * 4,
    label: '',
  },
  {
    value: (100 / 12) * 5,
    label: '',
  },
  {
    value: (100 / 12) * 6,
    label: '',
  },
  {
    value: (100 / 12) * 7,
    label: '',
  },
  {
    value: (100 / 12) * 8,
    label: '',
  },
  {
    value: (100 / 12) * 9,
    label: '',
  },
  {
    value: (100 / 12) * 10,
    label: '',
  },
  {
    value: (100 / 12) * 11,
    label: '',
  },
  // {
  //   value: (100 / 12) * 12,
  //   label: '',
  // },
]

export function transformSchedulesToDaySlots(schedules: IScheduleObject[]) {

  const mondayMorningSchedules = schedules.filter((each) => each.day_of_week === 0 && each.shift === MORNING);
  const mondayAfternoonSchedules = schedules.filter((each) => each.day_of_week === 0 && each.shift === AFTERNOON);


  const tuesdayMorningSchedules = schedules.filter((each) => each.day_of_week === 1 && each.shift === MORNING);
  const tuesdayAfternoonSchedules = schedules.filter((each) => each.day_of_week === 1 && each.shift === AFTERNOON);

  const wednesdayMorningSchedules = schedules.filter((each) => each.day_of_week === 2 && each.shift === MORNING);
  const wednesdayAfternoonSchedules = schedules.filter((each) => each.day_of_week === 2 && each.shift === AFTERNOON);

  const thursdayMorningSchedules = schedules.filter((each) => each.day_of_week === 3 && each.shift === MORNING);
  const thursdayAfternoonSchedules = schedules.filter((each) => each.day_of_week === 3 && each.shift === AFTERNOON);

  const fridayMorningSchedules = schedules.filter((each) => each.day_of_week === 4 && each.shift === MORNING);
  const fridayAfternoonSchedules = schedules.filter((each) => each.day_of_week === 4 && each.shift === AFTERNOON);

  const saturdayMorningSchedules = schedules.filter((each) => each.day_of_week === 5 && each.shift === MORNING);
  const saturdayAfternoonSchedules = schedules.filter((each) => each.day_of_week === 5 && each.shift === AFTERNOON);

  const sundayMorningSchedules = schedules.filter((each) => each.day_of_week === 6 && each.shift === MORNING);
  const sundayAfternoonSchedules = schedules.filter((each) => each.day_of_week === 6 && each.shift === AFTERNOON);

  const monday = mondayAfternoonSchedules.length > 0 && mondayMorningSchedules.length > 0 ? {
    value1: [
      parseInt(mondayMorningSchedules[0].time.slice(0, 2)),
      parseInt(mondayMorningSchedules[mondayMorningSchedules.length - 1].time.slice(0, 2)),
    ],
    value2: [
      parseInt(mondayAfternoonSchedules[0].time.slice(0, 2)) - 12,
      parseInt(mondayAfternoonSchedules[mondayAfternoonSchedules.length - 1].time.slice(0, 2)) - 12,
    ],
    day: MONDAY,
    checked: true,
  } : null

  const tuesday = tuesdayMorningSchedules.length > 0 && tuesdayAfternoonSchedules.length > 0 ? {
    value1: [
      parseInt(tuesdayMorningSchedules[0].time.slice(0, 2)),
      parseInt(tuesdayMorningSchedules[tuesdayMorningSchedules.length - 1].time.slice(0, 2)),
    ],
    value2: [
      parseInt(tuesdayAfternoonSchedules[0].time.slice(0, 2)) - 12,
      parseInt(tuesdayAfternoonSchedules[tuesdayAfternoonSchedules.length - 1].time.slice(0, 2)) - 12,
    ],
    day: TUESDAY,
    checked: true,
  } : null

  const wednesday = wednesdayMorningSchedules.length > 0 && wednesdayAfternoonSchedules.length ? {
    value1: [
      parseInt(wednesdayMorningSchedules[0].time.slice(0, 2)),
      parseInt(wednesdayMorningSchedules[wednesdayMorningSchedules.length - 1].time.slice(0, 2)),
    ],
    value2: [
      parseInt(wednesdayAfternoonSchedules[0].time.slice(0, 2)) - 12,
      parseInt(wednesdayAfternoonSchedules[wednesdayAfternoonSchedules.length - 1].time.slice(0, 2)) - 12,
    ],
    day: WEDNESDAY,
    checked: true,
  } : null

  const thursday = thursdayMorningSchedules.length > 0 && thursdayAfternoonSchedules.length > 0 ? {
    value1: [
      parseInt(thursdayMorningSchedules[0].time.slice(0, 2)),
      parseInt(thursdayMorningSchedules[thursdayMorningSchedules.length - 1].time.slice(0, 2)),
    ],
    value2: [
      parseInt(thursdayAfternoonSchedules[0].time.slice(0, 2)) - 12,
      parseInt(thursdayAfternoonSchedules[thursdayAfternoonSchedules.length - 1].time.slice(0, 2)) - 12,
    ],
    day: THURSDAY,
    checked: true,
  } : null

  const friday = fridayMorningSchedules.length > 0 && fridayAfternoonSchedules.length > 0 ? {
    value1: [
      parseInt(fridayMorningSchedules[0].time.slice(0, 2)),
      parseInt(fridayMorningSchedules[fridayMorningSchedules.length - 1].time.slice(0, 2)),
    ],
    value2: [
      parseInt(fridayAfternoonSchedules[0].time.slice(0, 2)) - 12,
      parseInt(fridayAfternoonSchedules[fridayAfternoonSchedules.length - 1].time.slice(0, 2)) - 12,
    ],
    day: FRIDAY,
    checked: true,
  } : null

  const saturday = saturdayMorningSchedules.length > 0 && saturdayAfternoonSchedules.length > 0 ? {
    value1: [
      parseInt(saturdayMorningSchedules[0].time.slice(0, 2)),
      parseInt(saturdayMorningSchedules[saturdayMorningSchedules.length - 1].time.slice(0, 2)),
    ],
    value2: [
      parseInt(saturdayAfternoonSchedules[0].time.slice(0, 2)) - 12,
      parseInt(saturdayAfternoonSchedules[saturdayAfternoonSchedules.length - 1].time.slice(0, 2)) - 12,
    ],
    day: SATURDAY,
    checked: true,
  } : null

  const sunday = sundayMorningSchedules.length > 0 && sundayAfternoonSchedules.length > 0 ? {
    value1: [
      parseInt(sundayMorningSchedules[0].time.slice(0, 2)),
      parseInt(sundayMorningSchedules[sundayMorningSchedules.length - 1].time.slice(0, 2)),
    ],
    value2: [
      parseInt(sundayAfternoonSchedules[0].time.slice(0, 2)) - 12,
      parseInt(sundayAfternoonSchedules[sundayAfternoonSchedules.length - 1].time.slice(0, 2)) - 12,
    ],
    day: SUNDAY,
    checked: true,
  } : null

  const returnedSchedules = [monday, tuesday, wednesday, thursday, friday, saturday, sunday].filter(each => each !== null)
  return returnedSchedules
}

export function transformDaySlotsToSchedule(dateSlots: {
  value1: number[]
  value2: number[]
  day: string
  checked: boolean
}[], create_username:string) {
  const daysOfWeek: {
    [key: string]: number
  } = {
    MONDAY: 0,
    TUESDAY: 1,
    WEDNESDAY: 2,
    THURSDAY: 3,
    FRIDAY: 4,
    SATURDAY: 5,
    SUNDAY: 6,
  };

  const mySchedules: IScheduleObject[] = [];

  dateSlots.forEach(slot => {
    const { value1, value2, day, checked } = slot;

    if (!checked) return;

    const dayOfWeek = daysOfWeek[day];

    for (let i = value1[0]; i <= value1[1]; i++) {
      mySchedules.push({
        day_of_week: dayOfWeek,
        time: `${i.toString().padStart(2, '0')}:00:00`,
        create_username
      });
    }
    for (let i = value2[0] + 12; i <= value2[1] + 12; i++) {
      mySchedules.push({
        day_of_week: dayOfWeek,
        time: `${i.toString().padStart(2, '0')}:00:00`,
        create_username
      });
    }
  });

  return mySchedules;
}

// Function to compare two arrays and return items present in one array and not in the other
export function compareSchedules(newSchedules: IScheduleObject[], oldSchedules:IScheduleObject[]) {
  const itemsOnlyInOld = oldSchedules.filter(oldItem => {
      return !newSchedules.some(newItem => {
          return oldItem.day_of_week === newItem.day_of_week && oldItem.time === newItem.time;
      });
  });

  const itemsOnlyInNew = newSchedules.filter(newItem => {
      return !oldSchedules.some(oldItem => {
          return oldItem.day_of_week === newItem.day_of_week && oldItem.time === newItem.time;
      });
  });

  return [itemsOnlyInOld, itemsOnlyInNew];
}


/* eslint-disable camelcase */
import { Modal } from 'react-bootstrap'
import InputContainer from '@components/form/InputContainer'
import FormButton from '@components/form/FormButton'
import { ChangeEventHandler, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'src/store'
import * as servicesSlice from '../../store/ServicesSlice'
import * as servicesService from '../../services/serviceServices'
import * as branchServices from '../../services/branchServices'
import DeleteButton from '@components/form/DeleteButton'
import Select, { MultiValue } from 'react-select'

type Props = {
  show: boolean
  onHide: () => void
  service: servicesSlice.Service
}
interface BranchOptions {
  branch_id: number
  label: string
  value: string
}

const EditAndDeleteModal = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>()
  const { service } = props
  const theServiceDetails = service
  const [updateServiceDetails, setUpdateServiceDetails] = useState(theServiceDetails)
  const branchOptions = useSelector((state: AppState) => state.services.branchOptions)
  const allBranchesOptions = useSelector((state: AppState) => state.services.allBranches)
  const fetchedBranches = useSelector((state: AppState) => state.services.fetchedBranches)
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState(false)
  const [loaded, setLoaded] = useState(false)

  const handleIsVisibleChange = (e: any) => {
    const value = e.target.value
    let newValue
    if (value === 'true') {
      newValue = true
    } else if (value === 'false') {
      newValue = false
    }

    setUpdateServiceDetails({
      ...updateServiceDetails,
      is_visible: newValue,
    })
  }

  useEffect(() => {
    setUpdateServiceDetails(theServiceDetails)
  }, [theServiceDetails])

  useEffect(() => {
    const getBranches = async () => {
      const fetchedBranches = await servicesService.getService(updateServiceDetails.id as number)
      if (fetchedBranches) {
        setLoaded(true)
      }
      dispatch(servicesSlice.setFetchedBranches(fetchedBranches.branches))
      fetchedBranches.branches.map((eachBranch: { id: number; name: string }) => {
        const branchOptionsDropdown = {} as BranchOptions
        branchOptionsDropdown.branch_id = eachBranch.id as number
        branchOptionsDropdown.label = eachBranch.name
        branchOptionsDropdown.value = eachBranch.name.toLowerCase()

        if (branchOptions?.length !== fetchedBranches.length) {
          dispatch(servicesSlice.setBranchOptions(branchOptionsDropdown))
        }
        return eachBranch
      })
    }
    getBranches()
  }, [])
  useEffect(() => {
    const getAllBranches = async () => {
      const fetchedAllBranches = await branchServices.getAllBranches()
      fetchedAllBranches.map((eachBranch: { id: number; name: string }) => {
        const allBranchesOptionsDropdown = {} as BranchOptions
        allBranchesOptionsDropdown.branch_id = eachBranch.id as number
        allBranchesOptionsDropdown.label = eachBranch.name
        allBranchesOptionsDropdown.value = eachBranch.name.toLowerCase()
        if (allBranchesOptions?.length !== fetchedAllBranches.length) {
          dispatch(servicesSlice.setAllBranches(allBranchesOptionsDropdown))
        }
        return eachBranch
      })
    }
    getAllBranches()
  }, [])

  const updateBranchOptions = (branches: MultiValue<BranchOptions>) => {
    dispatch(servicesSlice.updateBranchOptions(branches))
  }
  const updateService = async () => {
    setIsSaving(true)
    if (
      updateServiceDetails.name.length > 0 &&
      updateServiceDetails.desc.length > 0 &&
      updateServiceDetails.duration.toString().trim() !== '' &&
      updateServiceDetails.limit_booking.toString().trim() !== ''
    ) {
      console

      const updatedService = await servicesService.updateService(updateServiceDetails)
      if (updatedService) {
        for (const eachBranch of fetchedBranches) {
          const serviceBranches = {
            branch_id: eachBranch.id,
            service_id: updateServiceDetails.id as number,
          }
          await servicesService.deleteServiceBranch(serviceBranches)
        }

        const serviceWithNoBranch = await servicesService.getService(
          updateServiceDetails.id as number,
        )
        if (
          serviceWithNoBranch &&
          serviceWithNoBranch.branches &&
          (serviceWithNoBranch.branches.length as number) === 0
        ) {
          if (branchOptions.length > 0) {
            for (const eachBranch of branchOptions) {
              const serviceBranches = {
                branch_id: eachBranch.branch_id,
                service_id: updateServiceDetails.id as number,
              }
              const updatedServiceWithBranches = await servicesService.updateServiceBranches(
                serviceBranches,
              )
              if (
                updatedServiceWithBranches &&
                updatedServiceWithBranches.branches &&
                (updatedServiceWithBranches.branches.length as number) === branchOptions.length
              ) {
                dispatch(servicesSlice.selectService(updatedService))
                const services = await servicesService.getAllServices()
                dispatch(servicesSlice.allServices(services))
                dispatch(servicesSlice.setServiceBranches(updatedServiceWithBranches.branches))
                setIsSaving(false)
                props.onHide()
              }
            }
          } else {
            const services = await servicesService.getAllServices()
            dispatch(servicesSlice.selectService(updatedService))
            dispatch(servicesSlice.allServices(services))
            dispatch(servicesSlice.setServiceBranches([]))
            setIsSaving(false)
            props.onHide()
          }
        }
      }
    } else {
      setError(true)
    }
  }
  const deleteService = async () => {
    const deletedService = await servicesService.deleteService(updateServiceDetails)
    if (deletedService && deletedService.status === 204) {
      const services = await servicesService.getAllServices()
      dispatch(servicesSlice.selectService(services[0]))
      dispatch(servicesSlice.allServices(services))
      props.onHide()
    }
  }
  return (
    <Modal size='lg' {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>Edit Service Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loaded && (
          <>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='name' label='Service Name' required={true}>
                  <input
                    type='text'
                    defaultValue={updateServiceDetails.name}
                    onChange={(item) =>
                      setUpdateServiceDetails({
                        ...updateServiceDetails,
                        name: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-3'>
                <InputContainer name='icon' label='Service Icon'>
                  <input
                    type='text'
                    defaultValue={updateServiceDetails.icon}
                    onChange={(item) =>
                      setUpdateServiceDetails({
                        ...updateServiceDetails,
                        icon: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
              <div className='col-md-3'>
                <InputContainer name='code' label='Service Code' required={true}>
                  <input
                    type='text'
                    defaultValue={updateServiceDetails.code}
                    onChange={(item) =>
                      setUpdateServiceDetails({
                        ...updateServiceDetails,
                        code: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>
            </div>
            <InputContainer name='Description' label='Description' required={true}>
              <textarea
                defaultValue={updateServiceDetails.desc}
                onChange={(item) =>
                  setUpdateServiceDetails({ ...updateServiceDetails, desc: item.target.value })
                }
                rows={2}
              ></textarea>
            </InputContainer>
            <div className='row'>
              <div className='col-md-6'>
                <InputContainer name='duration' label='Service Duration' required={true}>
                  <div className='d-flex align-items-center'>
                    <input
                      type='number'
                      defaultValue={updateServiceDetails.duration}
                      onChange={(item) =>
                        setUpdateServiceDetails({
                          ...updateServiceDetails,
                          duration: item.target.value,
                        })
                      }
                    />
                    <span className=' '>Minutes</span>
                  </div>
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='booking' label='Limit Booking' required={true}>
                  <div className='d-flex align-items-center'>
                    <input
                      type='number'
                      defaultValue={updateServiceDetails.limit_booking}
                      onChange={(item) =>
                        setUpdateServiceDetails({
                          ...updateServiceDetails,
                          limit_booking: item.target.value,
                        })
                      }
                    />
                  </div>
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='location' label='Branches'>
                  <Select
                    instanceId='Branches'
                    defaultValue={branchOptions}
                    isMulti
                    onChange={(branches) => updateBranchOptions(branches)}
                    options={allBranchesOptions}
                    className='select'
                    classNamePrefix='select'
                  />
                </InputContainer>
              </div>
              <div className='col-md-6'>
                <InputContainer name='color' label='Color Code'>
                  <input
                    type='text'
                    defaultValue={updateServiceDetails.color}
                    onChange={(item) =>
                      setUpdateServiceDetails({
                        ...updateServiceDetails,
                        color: item.target.value,
                      })
                    }
                  />
                </InputContainer>
              </div>

              {/* Service isActive */}
              <div className='col-md-6'>
                <InputContainer name='is_visible' label='Is Visible'>
                  <select
                    value={updateServiceDetails.is_visible ? 'true' : 'false'}
                    onChange={handleIsVisibleChange}
                  >
                    <option value='true'>True</option>
                    <option value='false'>False</option>
                  </select>
                </InputContainer>
              </div>
            </div>
          </>
        )}
        <div className='text-danger text-center my-2'>
          {error && 'One or more items is required'}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <DeleteButton name='Service' onClick={deleteService}>
          Delete
        </DeleteButton>
        <FormButton className='ms-auto' loading={isSaving} onClick={updateService}>
          Save
        </FormButton>
      </Modal.Footer>
    </Modal>
  )
}
export default EditAndDeleteModal

import { useSelector, useDispatch } from 'react-redux'
import { AppState, AppDispatch } from 'src/store'
import * as servicesSlice from '../../store/ServicesSlice'
const SelectedServices = () => {
  const dispatch = useDispatch<AppDispatch>()
  const services = useSelector((state: AppState) => state.services.services)
  const selectedService = useSelector((state: AppState) => state.services.selectedService)
  const searchInput = useSelector((state: AppState) => state.services.searchInput)
  const filteredServices = services
    .filter((eachService) => {
      return eachService.name.toLowerCase().includes(searchInput.toLowerCase())
    })
    .sort((a, b) => {
      if (a.id !== undefined && b.id !== undefined) {
        return b.id - a.id
      }
      return 0
    })

  const showServiceDetails = (eachService: servicesSlice.Service) => {
    dispatch(servicesSlice.selectService(eachService))
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div>
      {filteredServices.map((eachService) => (
        <div
          className={
            eachService.id === selectedService.id ? 'list-item active' : 'list-item pointer'
          }
          key={eachService.name}
          onClick={() => showServiceDetails(eachService)}
        >
          <div>{eachService?.name}</div>
          <input
            type='checkbox'
            readOnly
            checked={eachService.id === selectedService.id ? true : false}
            className={eachService.id === selectedService.id ? 'ms-auto' : 'ms-auto pointer'}
          />
        </div>
      ))}
    </div>
  )
}
export default SelectedServices

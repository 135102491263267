import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import servicesReducer from './ServicesSlice'
import branchesReducer from './BranchesSlice'
import serviceProvidersReducer from './ServiceProvidersSlice'
import bookingsAppointmentReducer from './BookingsFilterSlice'
import updateBookingReducer from './UpdateBookingSlice'

export const store = configureStore({
  reducer: {
    services: servicesReducer,
    branches: branchesReducer,
    providers: serviceProvidersReducer,
    bookingsFilter: bookingsAppointmentReducer,
    updateBooking: updateBookingReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>

import ApiException from '@components/exceptions/ApiExceptions';
import axios from 'axios';

interface Error {
  response: {
    status: number;
  };
}

type FilteredBookingOptions = {
  branchId?: number | string;
  services?: number | string;
  status?: string;
  providers?: number | string;
  createdFrom?: string;
  createdTo?: string;
  dateFrom?: string;
  dateTo?: string;
  offset?: number;
  limit?: number;
};

type CancelBody = {
  reason: string;
  bookingId: string | number;
};

type ScheduleOptions = {
  providerId: number | string;
  dayOfWeek: number;
};

type ProviderSchedule = {
  time: string;
};

export const getAllFilteredBookings = async ({
  branchId,
  services,
  providers,
  status,
  createdFrom,
  createdTo,
  dateFrom,
  dateTo,
  offset,
  limit,
}: FilteredBookingOptions) => {
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/bookings?offset=${offset ? offset : 0}&limit=${
        limit ? limit : 1000000000
      }${services && `&services=${services}`}${status && `&status=${status}`}${
        providers && `&providers=${providers}`
      }${dateFrom && dateTo ? `&date_from=${dateFrom}&date_to=${dateTo}` : ''}${
        createdFrom && createdTo ? `&created_from=${createdFrom}&created_to=${createdTo}` : ''
      }${branchId && `&branch_id= ${branchId}`}`,
    );
    return result.data;
  } catch (error) {
    const err = error as Error;
    throw new ApiException(err.response?.status || 500);
  }
};

export const getAllBookingCount = async () => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/count_bookings`);
    return result.data;
  } catch (error) {
    const err = error as Error;
    throw new ApiException(err.response?.status || 500);
  }
};

export const cancelBooking = async ({ bookingId, reason }: CancelBody) => {
  try {
    const result = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/bookings/${Number(bookingId)}`,
      {
        cancelReason: reason,
        status: 'cancelled',
      },
    );
    return result;
  } catch (error) {
    const err = error as Error;
    throw new ApiException(err.response?.status || 500);
  }
};

export const getBookingById = async (bookingId: string | number) => {
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/bookings/${Number(bookingId)}`,
    );
    return result.data;
  } catch (error) {
    const err = error as Error;
    throw new ApiException(err.response?.status || 500);
  }
};

export const getBookingTokenValidation = async (bookingId: number, cancelToken: string) => {
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/booking_token/${bookingId}/${cancelToken}`,
    );
    return result.data;
  } catch (error) {
    const err = error as Error;
    throw new ApiException(err.response?.status || 500);
  }
};

export const getProviderSchedule = async ({
  providerId,
  dayOfWeek,
}: ScheduleOptions): Promise<ProviderSchedule[]> => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/providers/${providerId}/schedules?day_of_week=${dayOfWeek}`,
    );
    return response.data.map((schedule: any) => ({ time: schedule.time }));
  } catch (error) {
    const err = error as Error;
    throw new ApiException(err.response?.status || 500);
  }
};

type RescheduleBody = {
  bookingId: string | number;
  newDateTime: string;
};

export const rescheduleAppointment = async ({ bookingId, newDateTime }: RescheduleBody) => {
  try {
    /*eslint-disable*/
    const result = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/bookings/${Number(bookingId)}`,
      {
        status: 'confirmed',
        start_time: newDateTime,
      },
    );
    return result.data;
  } catch (error) {
    const err = error as Error;
    throw new ApiException(err.response?.status || 500);
  }
};

import { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import * as providerServices from '../../services/providerServices';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store';
import LoadingSpinner from '@components/loading/LoadingSpinner';
import * as texts from '../exceptions/texts';
import Schedule from './Schedule';

export interface IScheduleObject {
  day_of_week: number;
  time: string;
  shift?: string;
  create_username?: string;
  write_username?: string;
  id?: number;
}

const ServiceProviderSchedule = () => {
  const handleHeaderLabel = (index: number) => {
    if (index === 0) return { left: '-2%' };
    else if (index === 12) return { left: '49%' };
    else if (index === 13) return { left: '50%' };
    else if (index > 13 && index < 25) return { left: `${(100 / 24) * (index - 1) * 0.997}%` };
    else if (index === 25) return { left: '99%' };
    else return { left: `${(100 / 24) * index * 0.997}%` };
  };

  const selectedProvider = useSelector((state: AppState) => state.providers.selectedProvider);

  const [schedules, setSchedules] = useState<IScheduleObject[]>([]);
  const [createSchedule, setCreateSchedule] = useState(false);

  const updateSchedules = (mySchedules: IScheduleObject[]) => {
    const sortedSchedules = sortSchedules(mySchedules);
    setSchedules(sortedSchedules);
    setCreateSchedule(false);
  };

  const sortSchedules = (mySchedules: IScheduleObject[]) => {
    return mySchedules.sort(
      (a, b) => {
        if (a.day_of_week !== b.day_of_week) {
          return a.day_of_week - b.day_of_week;
        }
        return a.time.localeCompare(b.time);
      },
    );
  };

  const { isLoading, isSuccess, isError } = useQuery(
    ['provider-schedule', selectedProvider?.id],
    () => {
      if (!selectedProvider.id) {
        throw new Error('Provider ID is required');
      }
      return providerServices.getSchedules(selectedProvider.id);
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCreateSchedule(false);
        const sortedSchedules = sortSchedules(data);
        setSchedules(sortedSchedules);
      },
      enabled: !!selectedProvider.id,
    },
  );

  return (
    <div className='provider-schedule-section'>
      <div className='headline ms-3 mb-2'>Provider Schedule</div>
      {isLoading && (
        <div className='text-center'>
          <LoadingSpinner />
        </div>
      )}
      {isError && (
        <Alert variant='light' className='label m-0 text-danger'>
          <i className='eha-level4 text-danger me-2' />
          {texts.FETCHING_FAILED}
        </Alert>
      )}
      {createSchedule && selectedProvider.id && (
        <Schedule
          handleHeaderLabel={handleHeaderLabel}
          updateSchedules={updateSchedules}
          schedules={schedules}
          slotId={selectedProvider.id}
        />
      )}
      {isSuccess && schedules.length === 0 && createSchedule === false && (
        <div className='provider-schedule-card'>
          <div>No schedule created for this provider</div>
          <div className='mt-3'>
            <Button onClick={() => setCreateSchedule(true)}>Create</Button>
          </div>
        </div>
      )}
      {isSuccess && schedules.length > 0 && selectedProvider.id && (
        <Schedule
          handleHeaderLabel={handleHeaderLabel}
          updateSchedules={updateSchedules}
          schedules={schedules}
          slotId={selectedProvider.id}
        />
      )}
    </div>
  );
}

export default ServiceProviderSchedule;

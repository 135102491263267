import React, { CSSProperties, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { AppState } from '../../store';
import * as providerServices from '../../services/providerServices';
import {
  SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY
} from '../../constants/constant';
import SlotSlider from './SlotSlider';
import { transformSchedulesToDaySlots, transformDaySlotsToSchedule, compareSchedules, headerTimeLabels } from '@utils/providerSlotUI';
import ProviderCalendarModal from '@components/service-providers/ProviderCalendarModal'
import { setShowProviderCalendarModal } from '../../store/UpdateBookingSlice';
import { IScheduleObject } from './ServiceProviderSchedule';

interface ISchedule {
  handleHeaderLabel: (ev: number) => CSSProperties | undefined;
  updateSchedules: (mySchedules: IScheduleObject[]) => void;
  schedules: IScheduleObject[];
  slotId: number
}

const Schedule: React.FC<ISchedule> = ({ handleHeaderLabel, updateSchedules, schedules, slotId }) => {
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState('');
  const { keycloak } = useKeycloak();
  const selectedProvider = useSelector((state: AppState) => state.providers.selectedProvider);
  const dispatch = useDispatch();
  const showProviderCalendarModal = useSelector((state: AppState) => state.updateBooking.showProviderCalendarModal);

  const [daySlots, setDaySlots] = useState([
    { value1: [8, 11], value2: [0, 5], day: SUNDAY, checked: false },
    { value1: [8, 11], value2: [0, 5], day: MONDAY, checked: true },
    { value1: [8, 11], value2: [0, 5], day: TUESDAY, checked: true },
    { value1: [8, 11], value2: [0, 5], day: WEDNESDAY, checked: true },
    { value1: [8, 11], value2: [0, 5], day: THURSDAY, checked: true },
    { value1: [8, 11], value2: [0, 5], day: FRIDAY, checked: true },
    { value1: [8, 11], value2: [0, 5], day: SATURDAY, checked: false },
  ]);

  const transformedDaySlots = transformSchedulesToDaySlots(schedules);

  React.useEffect(() => {
    const newDaySlots = daySlots.map(slot => {
      slot.checked = false;
      const isDayPresent = transformedDaySlots.find(each => each?.day === slot.day);

      if (isDayPresent) {
        slot.checked = true;
        slot.value1 = isDayPresent.value1;
        slot.value2 = isDayPresent.value2;
      }
      return slot;
    });

    setDaySlots(newDaySlots);
  }, [schedules]);

  const updateDaySlots = (day: string, checked: boolean, type?: string, value?: number[]) => {
    const newDaySlots = daySlots.map((slot) => {
      if (slot.day === day && Object.keys(slot).includes(type as string)) {
        return { ...slot, [type as string]: value };
      }
      if (slot.day === day) {
        return { ...slot, checked };
      }
      return slot;
    });
    setDaySlots(newDaySlots);
  };

  const isCreateDisabled = () => {
    const isDateSelected = daySlots.find(schedule => schedule.checked === true);
    if (!isDateSelected) {
      return true;
    }
    if (inProgress) {
      return true;
    }
    return false;
  };

  const shouldCreateScheduleDisabled = isCreateDisabled();

  const handleCreate = async () => {
    try {
      setInProgress(true);
      setError('');
      const providerId = selectedProvider.id as number;

      const preparedSchedules = transformDaySlotsToSchedule(daySlots, keycloak.tokenParsed?.email);

      for (const schedule of preparedSchedules) {
        await providerServices.createSchedule(providerId, schedule);
      }

      const mySchedules = await providerServices.getSchedules(providerId);
      updateSchedules(mySchedules);

    } catch (error) {
      setError('Oops, error creating schedule. Kindly contact admin.');
    } finally {
      setInProgress(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setInProgress(true);
      setError('');
      const providerId = selectedProvider.id as number;
      const selectedDaySlots = daySlots.filter(schedule => schedule.checked === true);
      const newSchedules = transformDaySlotsToSchedule(selectedDaySlots, keycloak.tokenParsed?.email);
/* eslint-disable camelcase */
      const [itemsOnlyInOld, itemsOnlyInNew] = compareSchedules(newSchedules, schedules);
      for (const item of itemsOnlyInOld) {
        const schedule = {
          time: item.time,
          is_active: false,
          write_username: keycloak.tokenParsed?.email
        };
        const scheduleId = item.id as number;
        await providerServices.updateSchedule(providerId, scheduleId, schedule);
      }
      for (const item of itemsOnlyInNew) {
        const schedule = { ...item };
        await providerServices.createSchedule(providerId, schedule);
      }

      const mySchedules = await providerServices.getSchedules(providerId);
      updateSchedules(mySchedules);

    } catch (error) {
      setError('Oops, error updating schedule. Kindly contact admin.');
    } finally {
      setInProgress(false);
    }
  };

  const handleAdvancedSettingsClick = () => {
    dispatch(setShowProviderCalendarModal(true));
  };

  return (
    <div className='provider-schedule-card justify-content-between'>
      <div className='provider-schedule-title'>
        Drag the slider to set working schedules, or turn the toggle off to mark a day as unavailable
      </div>

      <div className='provider-schedule-main styled-scrollbar'>
        <div className='provider-grid-section'>
          <div className='header-row'>
            <div className='' />
            <div className='header-time-labels'>
              {headerTimeLabels.map((item, index) => (
                <div key={index} className='header-time-label' style={handleHeaderLabel(index)}>
                  {item === 'x' ? '' : item}
                </div>
              ))}
            </div>
          </div>
          {daySlots.map((item, index) => (
            <div className='w-full' key={index}>
              <SlotSlider data={item} updateDaySlots={updateDaySlots} />
            </div>
          ))}
        </div>
      </div>
      <div className='mt-3 mt-3 d-flex justify-content-between align-items-center'>
        {error && <Alert variant='light' className='label m-0 text-danger mb-2'>{error}</Alert>}
        {schedules.length === 0 && <Button onClick={handleCreate} disabled={shouldCreateScheduleDisabled}>Save</Button>}
        {schedules.length > 0 && <Button onClick={handleUpdate} disabled={inProgress}>Update</Button>}
        <Button onClick={handleAdvancedSettingsClick}>Advanced Settings</Button>
      </div>

      {selectedProvider.id !== undefined && (
  <ProviderCalendarModal 
    show={showProviderCalendarModal} 
    onHide={() => dispatch(setShowProviderCalendarModal(false))} 
    providerId={selectedProvider.id} 
    slotId={slotId} 
  />
)}

    </div>
  );
};

export default Schedule;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IBooking {
  start_time: string;
  end_time: string;
  service_id: number;
  category_id: number;
  status: string;
  comment: string;
  provider_id: number;
  client_id: number;
  branch_id: number;
  id: number;
  is_active: boolean;
  code: string;
  create_username: string;
  create_date: string;
  write_username: string;
  write_date: string;
  cancel_reason: string;
  createDate: string | null; 
  selectedTime: string | null; 
  branch: {
    name: string;
    code: string;
    address: string;
    is_testcenter: boolean;
    id: number;
  };
  client: {
    name: string;
    email: string;
    phone: string;
    patient_ref: string;
    id: number;
    is_active: boolean;
  };
  service: {
    name: string;
    desc: string;
    code: string;
    color: string;
    is_visible: boolean;
    category_id: number;
    icon: string;
    duration: number;
    limit_booking: number;
    id: number;
  };
  provider: {
    name: string;
    desc: string;
    code: string;
    email: string;
    phone: string;
    provider_image: string;
    is_visible: boolean;
    enable_google_sync: boolean;
    client_limit: number;
    id: number;
  };
  date: string;
  time: string;
  scheduleId: number;
}

interface IUpdateBooking {
  showProviderCalendarModal: boolean;
  showCancelConfirmationModal: boolean;
  showCompletedModal: boolean;
  showCancelledBookingModal: boolean;
  booking: IBooking | null;
  showCancelModal: boolean;
  showRescheduleModal: boolean;
  showRescheduleConfirmationModal: boolean;
  showConfirmRescheduleModal: boolean;
  createDate: string | null; 
  selectedTime: string | null; 
}

const initialState: IUpdateBooking = {
  showProviderCalendarModal: false,
  showCancelConfirmationModal: false,
  showCompletedModal: false,
  showCancelledBookingModal: false,
  booking: null,
  showCancelModal: false,
  showRescheduleModal: false,
  showRescheduleConfirmationModal: false,
  showConfirmRescheduleModal: false,
  createDate: null,
  selectedTime: null, 
};

const updateBookingSlice = createSlice({
  name: 'updateBooking',
  initialState,
  reducers: {
    setShowProviderCalendarModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showProviderCalendarModal = payload;
    },
    setShowCancelConfirmationModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showCancelConfirmationModal = payload;
    },
    setShowCompletedModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showCompletedModal = payload;
    },
    setShowCancelledBookingModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showCancelledBookingModal = payload;
    },
    setShowCancelModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showCancelModal = payload;
    },
    setBooking: (state, { payload }: PayloadAction<IBooking | null>) => {
      state.booking = payload;
    },
    setShowRescheduleModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showRescheduleModal = payload;
    },
    setShowRescheduleConfirmationModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showRescheduleConfirmationModal = payload;
    },
    setShowConfirmRescheduleModal: (state, { payload }: PayloadAction<boolean>) => {
      state.showConfirmRescheduleModal = payload;
    },
    setCreateDate: (state, { payload }: PayloadAction<string>) => {
      state.createDate = payload;
    },
    setSelectedTime: (state, { payload }: PayloadAction<string>) => {
      state.selectedTime = payload;
    },
  },
});

export const {
  setShowProviderCalendarModal,
  setShowCancelConfirmationModal,
  setShowCompletedModal,
  setShowCancelledBookingModal,
  setBooking,
  setShowCancelModal,
  setShowRescheduleModal,
  setShowRescheduleConfirmationModal,
  setShowConfirmRescheduleModal,
  setCreateDate,
  setSelectedTime,
} = updateBookingSlice.actions;

export default updateBookingSlice.reducer;
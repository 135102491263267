import * as texts from './texts';


class ApiException extends Error{

  name: string;
  statusCode?: number;
  message: string

  constructor(statusCode?:number, message?: string) {
    super(message);
    this.name = 'Exception'; 
    this.statusCode = statusCode
    this.message = 'Unexpected Error occured'

    switch (this.statusCode) {
      case 400:
        this.message = texts.BAD_REQUEST
        break;
      case 401:
        this.message = texts.NOT_AUTHORIZED
        break;
        case 403:
          this.message = texts.FORBIDDEN
          break;
      case 404:
        this.message = texts.NOT_FOUND
        break;
      case 408:
        this.message = texts.TIME_OUT
        break;
      case 500:
        this.message = texts.SERVER_DOWN
        break;
      default:
        this.message;
        break;
    }
  } 
}

export default ApiException;

import { View } from 'react-big-calendar';
import {
  TimeLabels,
  TimeValues,
  SlotCombination,
  SlotLabels,
} from '../resources/providerCalendar';

export const CALENDAR_VIEWS: View[] = ['month', 'week', 'day'];

export const generateCustomTimeRanges = () => {
  return {
    morning: [
      { label: SlotLabels.MORNING_FIRST, from: '00:00', to: '07:59' },
      { label: SlotLabels.MORNING_SECOND, from: '15:00', to: '23:59' },
    ],
    afternoon: [
      { label: SlotLabels.AFTERNOON_FIRST, from: '00:00', to: '12:59' },
      { label: SlotLabels.AFTERNOON_SECOND, from: '20:00', to: '23:59' },
    ],
    night: [
      { label: SlotLabels.NIGHT_FIRST, from: '00:00', to: '19:59' },
      { label: SlotLabels.NIGHT_SECOND, from: '08:00', to: '23:59' },
    ],
    fullDay: [
      { label: SlotLabels.FULL_DAY_FIRST, from: '00:00', to: '08:00' },
      { label: SlotLabels.FULL_DAY_SECOND, from: '20:00', to: '23:59' },
    ],
    Off: [{ label: '', from: '00:00', to: '23:59' }],
  };
};

export const determineTitle = (from: string, to: string): string => {
  if (from === TimeValues.MIDNIGHT && to === TimeValues.EARLY_MORNING) {
    return '8AM - 3PM';
  }
  if (from === TimeValues.MIDNIGHT && to === TimeValues.NOON_1259PM) {
    return '1PM - 8PM';
  }
  if (from === TimeValues.MIDNIGHT && to === TimeValues.EVENING_1959) {
    return '8PM - 8AM';
  }
  if (from === TimeValues.MIDNIGHT && to === TimeValues.MORNING_8AM) {
    return '8AM - 8PM';
  }
  if (from === TimeValues.MIDNIGHT && to === TimeValues.END_OF_DAY) {
    return '12AM - 11:59PM';
  }
  return '';
};

export const determineSecondTitle = (from: string, to: string): string => {
  if (from === TimeValues.MIDNIGHT && to === TimeValues.EARLY_MORNING) {
    return TimeLabels.MORNING;
  }
  if (from === TimeValues.MIDNIGHT && to === TimeValues.NOON_1259PM) {
    return TimeLabels.AFTERNOON;
  }
  if (from === TimeValues.MIDNIGHT && to === TimeValues.EVENING_1959) {
    return TimeLabels.NIGHT;
  }
  if (from === TimeValues.MIDNIGHT && to === TimeValues.MORNING_8AM) {
    return TimeLabels.FULL_DAY;
  }
  if (from === TimeValues.MIDNIGHT && to === TimeValues.END_OF_DAY) {
    return TimeLabels.OFF;
  }
  return '';
};

export const SlotLabelCombination = {
  [SlotCombination.MORNING]: {
    first: 'Morning First Slot',
    second: 'Morning Second Slot',
  },
  [SlotCombination.AFTERNOON]: {
    first: 'Afternoon First Slot',
    second: 'Afternoon Second Slot',
  },
  [SlotCombination.NIGHT]: {
    first: 'Night First Slot',
    second: 'Night Second Slot',
  },
  [SlotCombination.FULL_DAY]: {
    first: 'Full Day First Slot',
    second: 'Full Day Second Slot',
  },
};

import {useKeycloak} from '@react-keycloak/web';
// import AccessDenied from '@views/AccessDenied';

const PrivateRoute = ({children}) => {
    const {keycloak, initialized} = useKeycloak();

    const isLoggedIn = keycloak.authenticated;

    if(!initialized){
        return '..loading';
    }

    return isLoggedIn ? children : keycloak.login();
};

export default PrivateRoute;
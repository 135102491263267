import React from 'react'

type InputContainerProps = {
  children: React.ReactNode;
  label: string;
  required?: boolean;
  name: string ,
  errors?: {
    [key: string]: {
      message: string
    }
  }
}
const InputContainer = ({name, label, children, required, errors}: InputContainerProps) => {
  return (
    <div className={`form-group col ${required ? 'required' : ''} ${errors && errors[name] ? 'error' : ''}`}>
      <label className="form-label">{label}</label>
      {errors && errors[name] && (
        <p className="form-text label text-danger">{errors[name].message? errors[name].message : `Invalid ${label}`}</p>
      )}
      {children}
    </div>
  )
}

export default InputContainer

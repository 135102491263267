import React, { useState } from 'react';
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';

import { getAllBranches } from '../../services/branchServices'
import { getAllProviders } from '../../services/providerServices'
import { getAllServices } from '../../services/serviceServices';
import {
  setStatus,
  setBranchFilter,
  setServiceFilter,
  setServiceProvider,
  setAppointmentDateFilter,
  setCreatedDateFilter,
  removeCreatedDateFilter,
  removeAppointmentDateFilter,
} from '../../store/BookingsFilterSlice';
import { AppState } from 'src/store';

type InputEventType = {
  value: string
}

type ItemType = {
  name: string,
  id?: number
}

// A debounced input react component
export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  children,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
  children?: JSX.Element
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <>
      <input {...props} value={value} onChange={e => setValue(e.target.value)} />
      {children}
    </>
  )
}

// A drop down Custom filter for react table component
export const BranchesFilter = () => {
  const dispatch = useDispatch()

  const { filter } = useSelector((state: AppState) => state.bookingsFilter)

  const { data } = useQuery(['branches'], () => getAllBranches(), { refetchOnWindowFocus: false })

  const filterChangeHandler = (ev: InputEventType | null) => {
    dispatch(setBranchFilter(ev ? ev.value : ''))
  }

  let options = []
  if (data && data.length > 0) {
    options = data.map((item: ItemType) => {
      const selectObject = {
        label: item.name,
        value: item.id
      }
      return selectObject
    })
  }

  return (
    <Select
      instanceId="long-value-select-services" id="long-value-select-services"
      options={options}
      className="select select-filter"
      classNamePrefix="select"
      placeholder={'Branches: All'}
      onChange={filterChangeHandler}
      isSearchable={true}
      isClearable={true}
    />
  )
}

// A drop down Custom filter for react table component
export const StatusFilter = () => {
  const dispatch = useDispatch()

  const { filter } = useSelector((state: AppState) => state.bookingsFilter)

  const filterChangeHandler = (ev: InputEventType | null) => {
    dispatch(setStatus(ev ? ev.value : ''))
  }

  const options = [{
    label: 'Pending',
    value: 'pending'
  }, {
    label: 'Confirmed',
    value: 'confirmed'
  }, {
    label: 'Cancelled',
    value: 'cancelled'
  }, {
    label: 'In Progress',
    value: 'in_progress'
  }, {
    label: 'Completed',
    value: 'completed'
  }]



  return (
    <Select
      instanceId="long-value-select-services" id="long-value-select-services"
      options={options}
      className="select select-filter"
      classNamePrefix="select"
      placeholder={'Status: All'}
      onChange={filterChangeHandler}
      isSearchable={true}
      isClearable={filter.status ? true : false}
    />
  )
}

// A drop down Custom filter for react table component
export const ServicesFilter = () => {
  const dispatch = useDispatch()

  const { filter } = useSelector((state: AppState) => state.bookingsFilter)

  const { data } = useQuery(['services'], () => getAllServices(), { refetchOnWindowFocus: false })

  const filterChangeHandler = (ev: InputEventType | null) => {
    dispatch(setServiceFilter(ev ? ev.value : ''))
  }

  let options = []
  if (data && data.length > 0) {
    options = data.map((item: ItemType) => {
      const selectObject = {
        label: item.name,
        value: item.id
      }
      return selectObject
    })
  }

  return (
    <Select
      instanceId="long-value-select-services" id="long-value-select-services"
      options={options}
      className="select select-filter"
      classNamePrefix="select"
      placeholder={'Service: All'}
      onChange={filterChangeHandler}
      isSearchable={true}
      isClearable={filter.service ? true : false}
    />
  )
}

export const ProvidersFilter = () => {
  const dispatch = useDispatch()

  const { filter } = useSelector((state: AppState) => state.bookingsFilter)

  const { data } = useQuery(['providers'], () => getAllProviders(), { refetchOnWindowFocus: false })

  const filterChangeHandler = (ev: InputEventType | null) => {
    dispatch(setServiceProvider(ev ? ev.value : ''))
  }

  let options = []
  if (data && data.length > 0) {
    options = data.map((item: ItemType) => {
      const selectObject = {
        label: item.name,
        value: item.id
      }
      return selectObject
    })
  }

  return (
    <Select
      instanceId="long-value-select"
      id="long-value-select-provider" options={options}
      className="select select-filter"
      classNamePrefix="select"
      placeholder={'Provider: All'}
      onChange={filterChangeHandler}
      isSearchable={true}
      isClearable={filter.serviceProvider ? true : false}
    />
  )
}

export const AppointmentDateFilter = () => {
  const [startDateFrom, setStartDateFrom] = useState<Date>()
  const [startDateTo, setStartDateTo] = useState<Date>()

  const dispatch = useDispatch()

  const dateFromChangeHandler = (ev: Date) => {
    ev ?
      dispatch(setAppointmentDateFilter({ placeHolder: 'from', isoDate: ev.toISOString().toString() }))
      : dispatch(removeAppointmentDateFilter('from'))
    setStartDateFrom(ev || '')
  }

  const dateToChangeHandler = (ev: Date) => {
    ev ?
      dispatch(setAppointmentDateFilter({ placeHolder: 'to', isoDate: ev.toISOString().toString() }))
      : dispatch(removeAppointmentDateFilter('to'))
    setStartDateTo(ev || '')
  }

  return (
    <div className='d-flex p-2 align-items-center date-range'>
      <i className="eha-appointment text-light me-2"></i>
      <DatePicker
        selected={startDateFrom}
        onChange={dateFromChangeHandler}
        isClearable={startDateFrom ? true : false}
        className="date-filter"
        placeholderText="Book Date Start"
      />
      <div className='m-2 text-white'>{' –  '}</div>
      <DatePicker
        selected={startDateTo}
        onChange={dateToChangeHandler}
        isClearable={startDateTo ? true : false}
        className="date-filter"
        placeholderText="Book Date End"
      />
    </div>
  )
}

export const CreatedDateFilter = () => {
  const [startDateFrom, setStartDateFrom] = useState<Date>()
  const [startDateTo, setStartDateTo] = useState<Date>()

  const dispatch = useDispatch()

  const dateFromChangeHandler = (ev: Date) => {
    ev ?
      dispatch(setCreatedDateFilter({ placeHolder: 'from', isoDate: ev.toISOString().toString() }))
      : dispatch(removeCreatedDateFilter('from'))
    setStartDateFrom(ev || '')
  }

  const dateToChangeHandler = (ev: Date) => {
    ev ?
      dispatch(setCreatedDateFilter({ placeHolder: 'to', isoDate: ev.toISOString().toString() }))
      : dispatch(removeCreatedDateFilter('to'))
    setStartDateTo(ev || '')
  }

  return (
    <div className='d-flex p-2 align-items-center date-range'>
      <i className="eha-appointment text-light me-2"></i>
      <DatePicker
        selected={startDateFrom}
        onChange={dateFromChangeHandler}
        isClearable={startDateFrom ? true : false}
        className="date-filter"
        placeholderText="Created Date Start"
      />
      <div className='m-2 text-white'>{' –  '}</div>
      <DatePicker
        selected={startDateTo}
        onChange={dateToChangeHandler}
        isClearable={startDateTo ? true : false}
        className="date-filter"
        placeholderText="Created Date End"
      />
    </div>
  )
}

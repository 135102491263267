import Layout from '@components/layout/Layout'
import SideNav from '@components/side-nav'
import ProviderDetails from './ProviderDetails'
import ProviderSchedule from './ProviderSchedule'
import SearchProviders from './SearchProviders'
import SelectedProviders from './SelectedProviders'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'src/store'
import { setLimit, setOffset } from '../../store/ServiceProvidersSlice'
import { useQuery } from '@tanstack/react-query'
import { countProviders } from '../../services/providerServices'
import { useState } from 'react'
import ServiceProviderSchedule from './ServiceProviderSchedule'
const ServiceProviders = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [count, setCount] = useState(0)
  const [totalValue] = useState(100)
  const { offset, limit, serviceProviders } = useSelector((state: AppState) => state.providers)
  const enablePrevious = () => {
    if (offset !== 0) {
      return true
    }
    return false
  }
  const isPrevious = enablePrevious()
  const enableNext = () => {
    if (offset >= 0 && serviceProviders.length === totalValue) {
      return true
    } // This indicates that we possibly have more providers in the db
    return false
  }
  const isNext = enableNext()
  const clickPrevious = () => {
    const newOffset = offset - totalValue
    const newLimit = limit - totalValue
    dispatch(setOffset(newOffset))
    dispatch(setLimit(newLimit))
  }
  const clickNext = () => {
    const newOffset = offset + totalValue
    const newLimit = limit + totalValue
    dispatch(setOffset(newOffset))
    dispatch(setLimit(newLimit))
  }

  useQuery(['provider-count'], () => countProviders(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const count = data?.count
      setCount(count)
    },
  })
  return (
    <Layout title='Service Providers'>
      <SideNav>
        <div className='row'>
          <div className='col-xl-4 col-lg-5 d-flex flex-column pe-4 mt-4 '>
            <div className='headline ml-2 mb-2'> Service Providers</div>
            <SearchProviders />
            <div className='d-flex justify-content-between'>
              <button
                className={isPrevious ? 'text-primary' : ''}
                disabled={isPrevious === false ? true : false}
                onClick={clickPrevious}
              >
                <i className='eha-left' />
              </button>
              <span>
                {offset + 1} - {limit} / {count}
              </span>
              <button
                className={isNext ? ' text-primary' : ''}
                disabled={isNext === false ? true : false}
                onClick={clickNext}
              >
                <i className='eha-right' />
              </button>
            </div>
            <SelectedProviders />
          </div>
          <div className='col-xl-8 col-lg-7 mt-4'>
            <ProviderDetails />

            <ServiceProviderSchedule />
            {/* <ProviderSchedule /> */}
          </div>
        </div>
      </SideNav>
    </Layout>
  )
}
export default ServiceProviders

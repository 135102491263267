import React from 'react';
import { flexRender } from '@tanstack/react-table';


const Table = ({ table, children }: any) => {

  type TableGenerics = typeof table.generics

  return (
    <div>
      <div className="pagination-bar d-flex align-items-center">
        {children}
        <div className="pagination ms-auto">
          <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
            <i className="eha-left" />
          </button>{' '}
          <span>
            Page{' '}
            <strong>
              {table.getState().pagination.pageIndex + 1} of {' '} {table.getPageCount()}
            </strong>{' '}
          </span>
          <button style={{ cursor: 'pointer' }} onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
            <i className="eha-right" />
          </button>
        </div>
      </div>
      <table className='eha-table'>
        <thead>
          {table.getHeaderGroups().map((headerGroup: TableGenerics) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header: TableGenerics) => {
                return (
                  <th key={header.id} colSpan={header.colSpan} className={header.column.columnDef.headerClassName}>
                    {header.isPlaceholder ? null : (
                      <div {...{ className: header.column.getCanSort() ? 'cursor-pointer select-none' : '', onClick: header.column.getToggleSortingHandler(), }}>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )} {{
                          asc: <i className="eha-up"></i>,
                          desc: <i className="eha-down"></i>
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row: TableGenerics) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell: TableGenerics) => {
                  return (
                    <td key={cell.id} title={cell.column.columnDef.header?.toString()} className={cell.column.columnDef.headerClassName}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Table;
